/**
 * @generated SignedSource<<c15dea4c31ca7b8579e4abc7432eb805>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkstreamItem_user$data = {
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"WorkstreamForm_user">;
  readonly " $fragmentType": "WorkstreamItem_user";
};
export type WorkstreamItem_user$key = {
  readonly " $data"?: WorkstreamItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkstreamItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkstreamItem_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkstreamForm_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "742bd16b37f81f1cf9df43e24c7d8d48";

export default node;
