/**
 * @generated SignedSource<<72f324b90e634a618837b0f31aa690f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ClientActiveStatusUpdateMutation$variables = {
  active: boolean;
  id: number;
};
export type ClientActiveStatusUpdateMutation$data = {
  readonly updateClientActiveStatus: {
    readonly client: {
      readonly active: boolean;
      readonly id: number;
    } | null | undefined;
  };
};
export type ClientActiveStatusUpdateMutation = {
  response: ClientActiveStatusUpdateMutation$data;
  variables: ClientActiveStatusUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "active",
        "variableName": "active"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "UpdateClientResult",
    "kind": "LinkedField",
    "name": "updateClientActiveStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "clients",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientActiveStatusUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientActiveStatusUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5850c398903e4e9beeb737fd9bf750b3",
    "id": null,
    "metadata": {},
    "name": "ClientActiveStatusUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ClientActiveStatusUpdateMutation(\n  $id: Int!\n  $active: Boolean!\n) {\n  updateClientActiveStatus(id: $id, active: $active) {\n    client {\n      id\n      active\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "94412119417971675705917b4308c8ab";

export default node;
