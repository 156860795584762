import { graphql } from "react-relay"

import { ProjectActivateMutation } from "./__generated__/ProjectActivateMutation.graphql"
import { ProjectArchiveMutation } from "./__generated__/ProjectArchiveMutation.graphql"
import { ProjectBulkCreateMutation } from "./__generated__/ProjectBulkCreateMutation.graphql"
import { ProjectBulkUpdateMutation } from "./__generated__/ProjectBulkUpdateMutation.graphql"
import { ProjectConfirmMutation } from "./__generated__/ProjectConfirmMutation.graphql"
import { ProjectCreateMutation } from "./__generated__/ProjectCreateMutation.graphql"
import { ProjectDuplicateMutation } from "./__generated__/ProjectDuplicateMutation.graphql"
import { ProjectLockTimeSheetMutation } from "./__generated__/ProjectLockTimeSheetMutation.graphql"
import { ProjectRescheduleMutation } from "./__generated__/ProjectRescheduleMutation.graphql"
import { ProjectTentativeMutation } from "./__generated__/ProjectTentativeMutation.graphql"
import { ProjectUnlockTimeSheetMutation } from "./__generated__/ProjectUnlockTimeSheetMutation.graphql"
import { ProjectUpdateMutation } from "./__generated__/ProjectUpdateMutation.graphql"
import { ProjectTeamUpdateMutation } from "~/mutations/__generated__/ProjectTeamUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { addLinkedRecord, commitMutationPromise } from "./helpers"
import * as relayids from "~/helpers/relayids"

import { showToast } from "~/containers/ToasterContainer"

import { Assignment, assignmentUpdater } from "./Assignment"

const projectManagerUpdater = (store, project) => {
  const viewer = store.getRoot().getLinkedRecord("current_user")

  // Confusing naming: The "manageable_projects" relationship returns type "project_manager"
  const existingManagers = viewer.getLinkedRecords("manageable_projects")

  const newManager = project.managers.find(
    (projectManager) => projectManager.user_id === viewer.getValue("id"),
  )

  if (newManager) {
    const newManagerProxy = store.get(
      relayids.projectManagers.encode(newManager.id),
    )
    viewer.setLinkedRecords(
      [...existingManagers, newManagerProxy],
      "manageable_projects",
    )
  } else {
    viewer.setLinkedRecords(
      existingManagers.filter((manager) => {
        const managerProxy = store.get(manager.getDataID())
        return (
          managerProxy.getLinkedRecord("project").getValue("id") !== project.id
        )
      }),
      "manageable_projects",
    )
  }
}

const projectCreateMutation = graphql`
  mutation ProjectCreateMutation(
    $input: ProjectCreateInput!
    $todaysDate: date!
  ) {
    action_project_create(input: $input) {
      project {
        id
        name
        account_id
        client_id
        active
        calc_end_date
        calc_start_date
        created_at
        rate_card_id
        references
        team_id
        confirmed
        is_template
        tags_computed
        workstreams_computed
        pricing_model_readable
        pricing_model
        emoji
        archivable
        project_roles {
          estimated_minutes
          project_id
          role_id
        }
        client {
          id
          active
          name
          image_key
          real_client
        }
        team {
          id
          name
        }
        milestones {
          id
          date: date_runn
          project_id
          note
          icon
        }
        members {
          id
          person_id
        }
        project_rates {
          id
          role_id
          project_id
          rate: rate_private
        }
        project_workstreams {
          workstream {
            id
            name
            archived
          }
        }
        phases {
          id
          name
          start_date: start_date_runn
          end_date: end_date_runn
          color
          project_id
        }
        other_costs(order_by: { date: asc }) {
          id
          project_id
          charge
          cost
          date: date_runn
        }
        managers {
          id
          user_id
          project {
            id
          }
        }
        rate_card {
          id
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        future_assignments_aggregate: assignments_aggregate(
          where: { end_date_iso: { _gte: $todaysDate } }
        ) {
          aggregate {
            count
          }
        }
        ...ProjectCustomEditor_project @relay(mask: false)
        ...ProjectGroupStats_project
        ...DetectedPeopleSection_project
      }
      account {
        id
        clients {
          id
          projects {
            id
          }
        }
        projects {
          id
        }
        projectTemplates: projects(where: { is_template: { _eq: true } }) {
          id
        }
        project_tags: tags(where: { model: { _eq: "project" } }) {
          id
          name
          model
          archived
        }
        tags {
          id
          name
          model
          archived
        }
        workstreams {
          id
          name
          archived
        }
      }
    }
  }
`

type CreateProjectRelayOptions = {
  variables: ProjectCreateMutation["variables"]
}

export const projectCreateRelay = async (
  options: CreateProjectRelayOptions,
) => {
  const { variables } = options

  const data = await commitMutationPromise<ProjectCreateMutation>(environment, {
    mutation: projectCreateMutation,
    variables,
    updater: (store, responseData) => {
      projectManagerUpdater(store, responseData.action_project_create.project)
    },
  })

  return data.action_project_create.project
}

const projectUpdateMutation = graphql`
  mutation ProjectUpdateMutation($input: ProjectUpdateInput!) {
    action_project_update(input: $input) {
      project {
        id
        name
        active
        references
        client_id
        rate_card_id
        team_id
        confirmed
        is_template
        pricing_model
        pricing_model_readable
        tags_computed
        workstreams_computed
        priority
        emoji
        total_budget: total_budget_private
        client {
          id
          active
          name
          id
          image_key
          internal
          website
          real_client
        }
        rate_card {
          id
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
        team {
          id
        }
        managers {
          id
          user_id
          project {
            id
          }
        }
        assignments {
          id
          person_id
          project_id
          role_id
          phase_id
          workstream_id
          note
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          total_minutes
          non_working_day
          updated_at
        }
        project_workstreams {
          workstream {
            id
            name
            archived
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        ...ProjectCustomEditor_project @relay(mask: false)
        ...ProjectGroupStats_project
      }
      account {
        id
        clients {
          id
        }
        project_tags: tags(where: { model: { _eq: "project" } }) {
          id
          name
          model
          archived
        }
        tags {
          id
          name
          model
          archived
        }
        workstreams {
          id
          name
          archived
        }
      }
    }
  }
`

type UpdateProjectRelayOptions = {
  variables: ProjectUpdateMutation["variables"]
}

export const projectUpdateRelay = async (
  options: UpdateProjectRelayOptions,
) => {
  const { variables } = options
  const data = await commitMutationPromise<ProjectUpdateMutation>(environment, {
    mutation: projectUpdateMutation,
    variables,
    updater: (store, responseData) => {
      projectManagerUpdater(store, responseData.action_project_update.project)
    },
  })

  return data.action_project_update.project
}

export const confirmProject = (
  variables: ProjectConfirmMutation["variables"],
) => {
  return commitMutationPromise<ProjectConfirmMutation>(environment, {
    mutation: graphql`
      mutation ProjectConfirmMutation($id: Int!) {
        confirmProject(id: $id) {
          project {
            id
            active
            confirmed
          }
        }
      }
    `,
    variables,
  })
}

export const tentativeProject = (
  variables: ProjectTentativeMutation["variables"],
) => {
  return commitMutationPromise<ProjectTentativeMutation>(environment, {
    mutation: graphql`
      mutation ProjectTentativeMutation($id: Int!) {
        tentativeProject(id: $id) {
          project {
            id
            active
            confirmed
          }
        }
      }
    `,
    variables,
  })
}

export const activateProjectRelay = (
  variables: ProjectActivateMutation["variables"],
) => {
  return commitMutationPromise<ProjectActivateMutation>(environment, {
    mutation: graphql`
      mutation ProjectActivateMutation($id: Int!) {
        activateProject(id: $id) {
          project {
            id
            active
          }
        }
      }
    `,
    variables,
  })
}

export const archiveProjectRelay = (
  variables: ProjectArchiveMutation["variables"],
) => {
  return commitMutationPromise<ProjectArchiveMutation>(environment, {
    mutation: graphql`
      mutation ProjectArchiveMutation($id: Int!) {
        archiveProject(id: $id) {
          project {
            id
            active
          }
        }
      }
    `,
    variables,
  })
}

const projectBulkCreateMutation = graphql`
  mutation ProjectBulkCreateMutation($input: ProjectBulkCreateInput!) {
    action_project_bulk_create(input: $input) {
      project {
        id
        name
        account_id
        client_id
        active
        calc_end_date
        calc_start_date
        created_at
        references
        team_id
        confirmed
        is_template
        tags_computed
        workstreams_computed
        pricing_model_readable
        pricing_model
        rate_card_id
        archivable
        project_roles {
          estimated_minutes
          project_id
          role_id
        }
        client {
          id
          active
          name
          image_key
        }
        members {
          id
        }
        project_workstreams {
          workstream {
            id
            name
            archived
          }
        }
        project_rates {
          id
          role_id
          project_id
          rate: rate_private
        }
        team {
          id
        }
        milestones {
          id
        }
        phases {
          id
        }
        other_costs(order_by: { date: asc }) {
          id
          project_id
          charge
          cost
          date: date_runn
        }
        managers {
          id
          user_id
          project {
            id
          }
        }
        rate_card {
          id
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        ...ProjectCustomEditor_project @relay(mask: false)
        ...ProjectGroupStats_project
      }
    }
  }
`

type projectBulkCreateRelayOptions = {
  variables: ProjectBulkCreateMutation["variables"]
}

export const projectBulkCreateRelay = (
  options: projectBulkCreateRelayOptions,
) => {
  const { variables } = options

  return commitMutationPromise<ProjectBulkCreateMutation>(environment, {
    mutation: projectBulkCreateMutation,
    variables,
    updater: (store, data) => {
      const payload = data.action_project_bulk_create
      const accountRelayId = relayids.accounts.encode(
        payload[0].project.account_id,
      )
      const account = store.get(accountRelayId)

      payload.forEach((projectData) => {
        // Add the client
        const client = store.get(
          relayids.clients.encode(projectData.project.client.id),
        )
        addLinkedRecord(account, "clients", client)

        // Add the project
        const project = store.get(
          relayids.projects.encode(projectData.project.id),
        )
        addLinkedRecord(account, "projects", project)

        // Update project managers
        projectManagerUpdater(store, projectData.project)
      })
    },
  }).then(() => {
    showToast({
      message: "Projects and Clients created",
      type: "success",
    })
  })
}

const projectBulkUpdateMutation = graphql`
  mutation ProjectBulkUpdateMutation($input: ProjectBulkUpdateInput!) {
    action_project_bulk_update(input: $input) {
      project {
        id
        name
        confirmed
        active
        archivable
        team_id
        client_id
        tags_computed
        priority
        client {
          name
          website
          image_key
        }
        managers {
          id
          user_id
          project {
            id
          }
        }
        team {
          name
        }
      }
    }
  }
`

type projectBulkUpdateRelayOptions = {
  variables: ProjectBulkUpdateMutation["variables"]
}

export const projectBulkUpdateRelay = async (
  options: projectBulkUpdateRelayOptions,
) => {
  const { variables } = options

  const data = await commitMutationPromise<ProjectBulkUpdateMutation>(
    environment,
    {
      mutation: projectBulkUpdateMutation,
      variables,
      updater: (store, responseData) => {
        const payload = responseData.action_project_bulk_update
        payload.forEach((projectData) => {
          // Update project managers
          projectManagerUpdater(store, projectData.project)
        })
      },
    },
  )

  const updatedProjects = data.action_project_bulk_update
    .map((value) => value.project)
    .flat()
  if (updatedProjects) {
    showToast({
      message: "Projects successfully updated",
      type: "success",
    })
  }

  return variables.input.projects
}

const projectRescheduleMutation = graphql`
  mutation ProjectRescheduleMutation($date: Date!, $id: Int!) {
    rescheduleProject(date: $date, id: $id) {
      project {
        id
        name
        calc_start_date
        calc_end_date
        assignments {
          id
          person_id
          project_id
          role_id
          phase_id
          workstream_id
          note
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          total_minutes
          non_working_day
          updated_at
        }
        milestones {
          id
          date: date_runn
          project_id
          note
          icon
        }
        phases {
          id
          name
          start_date: start_date_runn
          end_date: end_date_runn
          color
          project_id
        }
      }
    }
  }
`

type ProjectRescheduleRelayOptions = {
  variables: ProjectRescheduleMutation["variables"]
}

export const projectRescheduleRelay = async (
  options: ProjectRescheduleRelayOptions,
) => {
  const { variables } = options

  const data = await commitMutationPromise<ProjectRescheduleMutation>(
    environment,
    {
      mutation: projectRescheduleMutation,
      variables,
    },
  )

  const project = data.rescheduleProject.project
  if (project) {
    showToast({
      message: "Project successfully rescheduled",
      type: "success",
    })
  }

  return project
}

const projectDuplicateMutation = graphql`
  mutation ProjectDuplicateMutation(
    $input: ProjectDuplicateInput!
    $todaysDate: date!
    $plannerStartDate: date!
    $includeProjectTemplates: Boolean!
  ) {
    action_project_duplicate(input: $input) {
      project {
        id
        name
        active
        account_id
        client_id
        active
        calc_end_date
        calc_start_date
        created_at
        references
        team_id
        confirmed
        tags_computed
        workstreams_computed
        pricing_model
        pricing_model_readable
        total_budget: total_budget_private
        expenses_budget
        rate_card_id
        emoji
        archivable
        assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
          id
          person_id
          project_id
          role_id
          phase_id
          workstream_id
          note
          start_date: start_date_runn
          end_date: end_date_runn
          minutes_per_day
          is_billable
          is_template
          total_minutes
          non_working_day
          updated_at
        }
        project_roles {
          id
          estimated_minutes
          project_id
          role_id
        }
        project_workstreams {
          workstream {
            id
            name
            archived
          }
        }
        client {
          id
          active
          name
          image_key
        }
        managers {
          id
          user_id
          project {
            id
          }
        }
        team {
          id
          name
        }
        milestones {
          id
          date: date_runn
          project_id
          note
          icon
          title
        }
        members {
          id
          project_id
          person_id
          role_id
          workstream_id
          person {
            id
            active
          }
        }
        project_rates {
          id
          role_id
          project_id
          rate: rate_private
        }
        phases {
          id
          name
          start_date: start_date_runn
          end_date: end_date_runn
          color
          project_id
        }
        other_costs(order_by: { date: asc }) {
          id
          project_id
          charge
          cost
          date: date_runn
          name
        }
        rate_card {
          id
          name
          blended_rate: blended_rate_private
          blended_rate_card
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
        actuals_aggregate {
          aggregate {
            count
          }
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        future_assignments_aggregate: assignments_aggregate(
          where: { end_date_iso: { _gte: $todaysDate } }
        ) {
          aggregate {
            count
          }
        }
        links {
          id
          name
          href
          show_in_planner
        }
        ...ProjectCustomEditor_project @relay(mask: false)
      }
      account {
        id
        projects {
          id
          is_template
        }
        projectTemplates: projects(where: { is_template: { _eq: true } })
          @include(if: $includeProjectTemplates) {
          id
          name
          pricing_model
          pricing_model_readable
          tags_computed
          workstreams_computed
          client {
            id
            name
            website
            image_key
          }
          team {
            id
            name
          }
        }
        people {
          id
          active
          first_name
          last_name
          email
          is_placeholder
          team_id
          tags
          references
          account_id
          archivable
          contracts {
            id
            start_date: start_date_runn
            end_date: end_date_runn
            minutes_per_day
            employment_type
            role_id
            cost: cost_private
            role {
              id
              name
            }
            job_title
          }
          competencies {
            id
            person_id
            skill_id
            level
            skill {
              id
              name
            }
          }
          time_offs(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
          }
          team {
            id
          }
          assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) {
            id
          }
          people_notes {
            id
          }
          custom_text_values {
            id
            value
            typeId: custom_text_type_id
          }
          custom_select_values {
            id
            optionId: custom_select_option_id
            typeId: custom_select_type_id
          }
          custom_checkbox_values {
            id
            typeId: custom_checkbox_type_id
            value
          }
          custom_date_values {
            id
            typeId: custom_date_type_id
            value
          }
          project_memberships {
            id
            has_actuals
            role_id
            workstream_id
            project_id
            project {
              id
              is_template
            }
          }
          person_requests {
            id
          }
          placeholder_suggestions {
            suggested_person_id
          }
        }
        workstreams {
          id
          name
          archived
        }
      }
    }
  }
`

type ProjectDuplicateRelayOptions = {
  variables: ProjectDuplicateMutation["variables"]
}

export const projectDuplicateRelay = async (
  options: ProjectDuplicateRelayOptions,
) => {
  const { variables } = options
  const isCreatedFromTemplate = Boolean(variables.input?.new_project_input)

  const data = await commitMutationPromise<ProjectDuplicateMutation>(
    environment,
    {
      mutation: projectDuplicateMutation,
      variables,
      updater: (store, res) => {
        const payload = res.action_project_duplicate
        payload.project.assignments.forEach((assignmentData) => {
          const relayId = relayids.assignments.encode(assignmentData.id)
          const assignment = store.get<Assignment>(relayId)
          assignmentUpdater(store, assignment)
        })

        projectManagerUpdater(store, payload.project)
      },
    },
  )

  const project = data.action_project_duplicate.project

  if (project && !isCreatedFromTemplate) {
    showToast({
      message: "Project successfully duplicated",
      type: "success",
    })
  }

  return project
}

export const lockProjectTimeSheet = async (
  variables: ProjectLockTimeSheetMutation["variables"],
) => {
  const data = await commitMutationPromise<ProjectLockTimeSheetMutation>(
    environment,
    {
      mutation: graphql`
        mutation ProjectLockTimeSheetMutation(
          $id: Int!
          $timesheetLockedTo: Date!
        ) {
          lockProjectTimeSheet(id: $id, timesheetLockedTo: $timesheetLockedTo) {
            project {
              id
              timesheet_lock_status
              timesheet_locked_to
              timesheet_lock_status_updated_at
            }
          }
        }
      `,
      variables,
    },
  )

  return data.lockProjectTimeSheet
}

export const unlockProjectTimeSheet = async (
  variables: ProjectUnlockTimeSheetMutation["variables"],
) => {
  const data = await commitMutationPromise<ProjectUnlockTimeSheetMutation>(
    environment,
    {
      mutation: graphql`
        mutation ProjectUnlockTimeSheetMutation($id: Int!) {
          unlockProjectTimeSheet(id: $id) {
            project {
              id
              timesheet_lock_status
              timesheet_locked_to
              timesheet_lock_status_updated_at
            }
          }
        }
      `,
      variables,
    },
  )

  return data.unlockProjectTimeSheet
}

const projectTeamUpdateMutation = graphql`
  mutation ProjectTeamUpdateMutation($id: Int!, $teamId: Int) {
    updateProjectTeam(input: { id: $id, teamId: $teamId }) {
      project {
        id
        name
      }
      teamId
      account {
        id
        teams {
          id
          ...TeamProjects_team
        }
      }
    }
  }
`

export const projectTeamUpdateRelay = async (
  variables: ProjectTeamUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<ProjectTeamUpdateMutation>(
    environment,
    {
      mutation: projectTeamUpdateMutation,
      variables,
    },
  )

  return data.updateProjectTeam
}
