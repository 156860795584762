import React, { useMemo } from "react"
import { Control, Controller, UseFormGetValues } from "react-hook-form"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./Panel.module.css"

import { ProjectsPanelQuery } from "./__generated__/ProjectsPanelQuery.graphql"

import Select from "~/common/Select"
import Callout from "~/common/Text/Callout"
import Support from "~/common/Text/SupportingText"
import Checkbox from "~/common/inputs/Checkbox"

import { UserForm } from "../userForm"

const PROJECT_ACCESS_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Restricted",
    value: "restricted",
  },
]

const QUERY = graphql`
  query ProjectsPanelQuery {
    current_user {
      id
      account {
        id
        projects(
          where: { is_template: { _eq: false }, active: { _eq: true } }
        ) {
          id
          name
        }
      }
    }
  }
`

const ProjectAccessLabel = <div className={styles.label}>Project Access</div>
const ManagedProjectsLabel = (
  <div className={styles.label}>Managed Projects</div>
)

type Props = {
  control: Control<UserForm>
  getValues: UseFormGetValues<UserForm>
}

const ProjectsPanel = ({ control, getValues }: Props) => {
  const data = useLazyLoadQuery<ProjectsPanelQuery>(QUERY, {})
  const { projects } = data.current_user.account

  const manageProjectsPermission = getValues("permissions.manage_projects")
  const managePeoplePermission = getValues("permissions.manage_people")

  const projectOptions = useMemo(
    () =>
      (projects ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [projects],
  )

  return (
    <div className={styles.panel}>
      <div>
        <Controller
          control={control}
          name="permissions.manage_projects"
          render={({ field: { onChange, value } }) => (
            <Select
              className={styles.input}
              onChange={(e) => onChange(e.value)}
              label={ProjectAccessLabel}
              value={PROJECT_ACCESS_OPTIONS.find(
                (option) => option.value === value,
              )}
              options={PROJECT_ACCESS_OPTIONS}
            />
          )}
        />
        <Support>
          {getValues("permissions.manage_projects") === "all"
            ? "Can edit All Projects"
            : "Can edit selected Projects"}
        </Support>
      </div>
      {managePeoplePermission !== "all" && (
        <div>
          <div className={styles.section}>
            <Controller
              control={control}
              name="permissions.add_all_people_to_projects"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="add_all_people_to_projects"
                  label="Can assign all People from the account to Managed Projects"
                  labelClassName={styles.checkboxLabel}
                  onChange={onChange}
                  checked={value}
                />
              )}
            />
          </div>
          {managePeoplePermission === "restricted" && (
            <Callout className={styles.attachedCallout}>
              {"Users can add their Managed People to projects by default. "}
            </Callout>
          )}
        </div>
      )}
      {manageProjectsPermission === "restricted" && (
        <div className={styles.section}>
          <Controller
            control={control}
            name="manageableProjects"
            render={({ field: { onChange, value } }) => (
              <Select
                id="managed-people"
                value={projectOptions.filter((option) =>
                  value.includes(option.value),
                )}
                isMulti
                onChange={(e) => onChange(e.map((item) => item.value))}
                label={ManagedProjectsLabel}
                options={projectOptions}
                placeholder="Type Project name..."
              />
            )}
          />
        </div>
      )}
    </div>
  )
}

export default ProjectsPanel
