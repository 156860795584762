/**
 * @generated SignedSource<<f6cd54c14ea5fe7831b498f8df319f90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ReferencesInput = {
  externalId: string;
  referenceName: string;
};
export type ClientUpdateMutation$variables = {
  id: number;
  name: string;
  references?: ReadonlyArray<ReferencesInput> | null | undefined;
  website?: string | null | undefined;
};
export type ClientUpdateMutation$data = {
  readonly updateClient: {
    readonly client: {
      readonly account: {
        readonly clients: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly id: number;
      };
      readonly account_id: number;
      readonly active: boolean;
      readonly archivable: boolean | null | undefined;
      readonly deletable: boolean | null | undefined;
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly name: string;
      readonly references: any;
      readonly website: string | null | undefined;
    } | null | undefined;
  };
};
export type ClientUpdateMutation = {
  response: ClientUpdateMutation$data;
  variables: ClientUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "references"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "website"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "references",
        "variableName": "references"
      },
      {
        "kind": "Variable",
        "name": "website",
        "variableName": "website"
      }
    ],
    "concreteType": "UpdateClientResult",
    "kind": "LinkedField",
    "name": "updateClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "clients",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image_key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "references",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "account_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "clients",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientUpdateMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientUpdateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5bd2fc54a4c44639ac4cbe92e0098157",
    "id": null,
    "metadata": {},
    "name": "ClientUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ClientUpdateMutation(\n  $id: Int!\n  $name: String!\n  $website: String\n  $references: [ReferencesInput!]\n) {\n  updateClient(id: $id, name: $name, website: $website, references: $references) {\n    client {\n      id\n      name\n      website\n      active\n      archivable\n      image_key\n      deletable\n      references\n      account_id\n      account {\n        id\n        clients {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d83e5ffaea65b5e40f31366dbcfb5c85";

export default node;
