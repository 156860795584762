import { Position } from "@blueprintjs/core"
import {
  DateInput as BlueprintDateInput,
  DateInputProps,
} from "@blueprintjs/datetime"
import classcat from "classcat"
import { addYears, format as formatDate, subYears } from "date-fns"
import React from "react"

import styles from "./DateInput.module.css"

// If the date string matches the ISO 8601 format include the time component so the date constructor
// interprets it as a local date instead of a UTC date. This is to be consistent with all other
// formats the date constructor accepts
const parseDate = (dateString: string) =>
  dateString.match(/^\d{4}-\d{2}-\d{2}$/)
    ? new Date(dateString + "T00:00:00")
    : new Date(dateString)

type BluePrintInputTypes = Omit<
  DateInputProps,
  "formatDate" | "parseDate" | "value" | "onChange"
>

type Props = {
  minDate?: Date
  maxDate?: Date
  className?: string
  label?: string
  error?: string
  tabIndex?: number
  dateFormat?: string
  dataTest?: string
  value?: Date | null
  onChange?: (value: Date | null) => void
  disabled?: boolean
  textAlignRight?: boolean
  showWeekNumbers?: boolean
} & BluePrintInputTypes

const DateInput = (props: Props) => {
  const {
    className,
    label,
    error,
    dateFormat = "d MMM yyyy",
    tabIndex,
    minDate = subYears(new Date(), 5),
    maxDate = addYears(new Date(), 5),
    dataTest,
    value,
    onChange,
    textAlignRight,
    showWeekNumbers = false,
    ...rest
  } = props

  return (
    <div
      className={classcat([
        styles.dateInput,
        className,
        {
          [styles.error]: Boolean(error),
          [styles.textAlignRight]: textAlignRight,
        },
      ])}
      data-test={dataTest}
    >
      <label>{label && `${label} ${error ?? ""}`}</label>
      <BlueprintDateInput
        popoverProps={{ position: Position.BOTTOM }}
        formatDate={(date) => formatDate(date, dateFormat)}
        parseDate={parseDate}
        minDate={minDate}
        maxDate={maxDate}
        inputProps={{
          tabIndex,
        }}
        dayPickerProps={{ showWeekNumbers }}
        value={value ? value.toDateString() : null}
        onChange={(date) => {
          if (date) {
            onChange && onChange(parseDate(date))
          } else {
            onChange && onChange(null)
          }
        }}
        {...rest}
      />
    </div>
  )
}

export default DateInput
