import { Alignment, Switch } from "@blueprintjs/core"
import React from "react"
import { Control, Controller, UseFormGetValues } from "react-hook-form"

import styles from "./Panel.module.css"

import Select from "~/common/Select"
import Support from "~/common/Text/SupportingText"

import HelpTooltip from "../HelpTooltip"
import { UserForm } from "../userForm"

const USER_TYPES_LINK = "https://help.runn.io/en/articles/9743063-user-types"
const FINANCIAL_LINK =
  "https://help.runn.io/en/articles/9754885-financial-permissions"
const ACCOUNT_SETTINGS_LINK =
  "https://help.runn.io/en/articles/9754839-edit-account-settings-and-data"

const USER_TYPE_OPTIONS = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Contributor",
    value: "contributor",
  },
]

const FINANCIAL_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Restricted",
    value: "restricted",
  },
  {
    label: "None",
    value: "none",
  },
]

const UserTypeLabel = (
  <div className={styles.label}>
    <span>User Type </span>
    <HelpTooltip
      text="Permissions for"
      linkText="user types"
      url={USER_TYPES_LINK}
    />
  </div>
)

const FinancialLabel = (
  <div className={styles.label}>
    <span>Financial </span>
    <HelpTooltip
      text="Permission settings for"
      linkText="financials"
      url={FINANCIAL_LINK}
    />
  </div>
)

type Props = {
  control: Control<UserForm>
  getValues: UseFormGetValues<UserForm>
}

const UserTypePanel = ({ control, getValues }: Props) => {
  const isAdmin = getValues("permissions.type") === "admin"
  const isContributor = getValues("permissions.type") === "contributor"
  const isManager = getValues("permissions.type") === "manager"

  const hasManageAccountPermission =
    getValues("permissions.manage_account") === true

  const isFinancialSelectVisible =
    (isAdmin && !hasManageAccountPermission) || isManager

  const viewPlannerSupport = getValues("permissions.view_planner")
    ? "Can view the planner"
    : "Can't view the planner (Timesheet only)"

  const managePeopleSupport = ["all", "restricted"].includes(
    getValues("permissions.manage_people"),
  )
    ? "Select the People that this user can manage"
    : "View only"

  const manageProjectsSupport = ["all", "restricted"].includes(
    getValues("permissions.manage_projects"),
  )
    ? "Select the Projects that this user can manage"
    : "View only"

  return (
    <div className={styles.panel}>
      <Controller
        control={control}
        name="permissions.type"
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={(e) => onChange(e.value)}
            label={UserTypeLabel}
            value={USER_TYPE_OPTIONS.find((option) => option.value === value)}
            options={USER_TYPE_OPTIONS}
            dataTest="user-type-select"
          />
        )}
      />
      {isContributor && (
        <div className={styles.section}>
          <div>
            <div className={styles.spaceBetween}>
              <strong>View Planner</strong>
              <Controller
                control={control}
                name="permissions.view_planner"
                render={({ field: { onChange, value } }) => (
                  <Switch
                    className={styles.switch}
                    label={value ? "On" : "Off"}
                    alignIndicator={Alignment.RIGHT}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
            </div>
            <Support>{viewPlannerSupport}</Support>
          </div>
        </div>
      )}
      {isManager && (
        <div className={styles.section}>
          <div>
            <div className={styles.spaceBetween}>
              <strong>Manage People</strong>
              <Controller
                control={control}
                name="permissions.manage_people"
                render={({ field: { onChange, value } }) => {
                  const checked = ["all", "restricted"].includes(value)
                  return (
                    <Switch
                      className={styles.switch}
                      label={checked ? "On" : "Off"}
                      alignIndicator={Alignment.RIGHT}
                      onChange={() => onChange(checked ? "none" : "all")}
                      checked={checked}
                    />
                  )
                }}
              />
            </div>
            <Support>{managePeopleSupport}</Support>
          </div>
          <div>
            <div className={styles.spaceBetween}>
              <strong>Manage Projects</strong>
              <Controller
                control={control}
                name="permissions.manage_projects"
                render={({ field: { onChange, value } }) => {
                  const checked = ["all", "restricted"].includes(value)
                  return (
                    <Switch
                      label={checked ? "On" : "Off"}
                      alignIndicator={Alignment.RIGHT}
                      onChange={() => onChange(checked ? "none" : "all")}
                      checked={checked}
                    />
                  )
                }}
              />
            </div>
            <Support>{manageProjectsSupport}</Support>
          </div>
        </div>
      )}
      {isAdmin && (
        <div className={styles.section}>
          <div className={styles.spaceBetween}>
            <span>
              Edit account settings and data{" "}
              <HelpTooltip
                text="Permissions settings for"
                linkText="account settings and data"
                url={ACCOUNT_SETTINGS_LINK}
              />
            </span>
            <Controller
              control={control}
              name="permissions.manage_account"
              render={({ field: { onChange, value } }) => (
                <Switch
                  className={styles.checkbox}
                  label={value ? "On" : "Off"}
                  alignIndicator={Alignment.RIGHT}
                  onChange={(newValue) => onChange(newValue)}
                  checked={value}
                />
              )}
            />
          </div>
        </div>
      )}
      {isFinancialSelectVisible && (
        <div className={styles.section}>
          <Controller
            control={control}
            name="permissions.financial"
            render={({ field: { onChange, value } }) => (
              <Select
                onChange={(a) => onChange(a.value)}
                label={FinancialLabel}
                value={FINANCIAL_OPTIONS.find(
                  (option) => option.value === value,
                )}
                options={FINANCIAL_OPTIONS}
                dataTest="financial-select"
              />
            )}
          />
        </div>
      )}
    </div>
  )
}

export default UserTypePanel
