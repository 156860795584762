/**
 * @generated SignedSource<<20ea80abb212a13dd3b7d6a5d51bc165>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateUserSelectedViewInput = {
  viewId?: number | null | undefined;
};
export type UserSelectedViewMutation$variables = {
  input: UpdateUserSelectedViewInput;
};
export type UserSelectedViewMutation$data = {
  readonly updateUserSelectedView: {
    readonly user: {
      readonly id: number;
      readonly view: {
        readonly id: number;
      } | null | undefined;
      readonly view_id: number | null | undefined;
    } | null | undefined;
  };
};
export type UserSelectedViewMutation = {
  response: UserSelectedViewMutation$data;
  variables: UserSelectedViewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserSelectedViewPayload",
    "kind": "LinkedField",
    "name": "updateUserSelectedView",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "view_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "views",
            "kind": "LinkedField",
            "name": "view",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSelectedViewMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSelectedViewMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1fa0578edb6dd4c60a3e5e6e1430948e",
    "id": null,
    "metadata": {},
    "name": "UserSelectedViewMutation",
    "operationKind": "mutation",
    "text": "mutation UserSelectedViewMutation(\n  $input: UpdateUserSelectedViewInput!\n) {\n  updateUserSelectedView(input: $input) {\n    user {\n      id\n      view_id\n      view {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "430ef4c3a49f3cdc2b743988c2aa57c8";

export default node;
