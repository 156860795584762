import { Tooltip } from "@blueprintjs/core"
import React, { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./DeleteAccountForm.module.css"

import { DeleteAccountFormQuery } from "./__generated__/DeleteAccountFormQuery.graphql"

import { reportError } from "~/helpers/error-helpers"
import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import routes, { sanitizePathname } from "~/helpers/routes"
import { getIsMemberOfOtherAccounts } from "~/helpers/user-helpers"

import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramAlert } from "~/common/tangrams/tangramAlert"

import { cancelReasonsCreateRelay } from "~/mutations/CancelReasons"

import { showToast } from "~/containers/ToasterContainer"

import CancelReasonsForm, { formattedReasons } from "./CancelReasonsForm"

type Props = {
  account: {
    name: string
    id: number
    subscription: {
      id: string
      status: string
      mrr?: number
    }
    cancel_reasons?: ReadonlyArray<{
      account_name: string
    }>
  }
  onClose: () => void
}

const deleteAccount = () => {
  fetchWithCsrf("/account", { method: "DELETE" })
    .then(() =>
      setTimeout(
        () =>
          (window.location.href = sanitizePathname(routes.accountDeletedUrl())),
        4000,
      ),
    )
    .catch((e) => {
      {
        void reportError(`deleteAccount error: `, e)
        showToast({
          message:
            "Sorry, we couldn't delete your account. Please refresh the page and try again.",
          type: "error",
        })
      }
    })
}
const DELETE_ACCOUNT_FORM_QUERY = graphql`
  query DeleteAccountFormQuery {
    current_user {
      user_accounts {
        id
        account {
          id
          name
          account_type
          alternative_account_id
        }
      }
    }
  }
`

const DeleteAccountForm = (props: Props) => {
  const { account, onClose } = props
  const subscription = account.subscription

  const [deleteValue, setDeleteValue] = useState("")
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectedReasons, setSelectedReasons] = useState([])

  const { current_user } = useLazyLoadQuery<DeleteAccountFormQuery>(
    DELETE_ACCOUNT_FORM_QUERY,
    {},
  )

  const handleValue = (e) => {
    setDeleteValue(e.target.value)
  }

  const handleClose = () => {
    if (!isDeleting) {
      onClose()
      setDeleteValue("")
    }
  }

  const handleSubmit = async () => {
    setIsDeleting(true)

    try {
      await cancelReasonsCreateRelay({
        variables: {
          reasons: formattedReasons(account, subscription, selectedReasons),
        },
      })
      deleteAccount()
    } catch {
      setIsDeleting(false)
    }
  }

  const DeleteInputLabel = () => {
    return (
      <div style={{ fontSize: 16 }}>
        Type your account name{" "}
        <span className={`${styles.red} notranslate`}>{account.name}</span> to
        confirm
      </div>
    )
  }

  const showCancelReasonForm =
    (!account.cancel_reasons || account.cancel_reasons.length === 0) &&
    !getIsMemberOfOtherAccounts(current_user.user_accounts, account.id)

  const formNeedsToBeFilled =
    showCancelReasonForm &&
    (!selectedReasons.length ||
      selectedReasons.some(
        (r) =>
          r.reason !== "This is a duplicate account" && r.explanation === "",
      ))

  return (
    <ModalFormWrapper
      headerTitle={`Delete ${account.name} Account`}
      tangram={<TangramAlert />}
      tangramStyles={{ marginBottom: 20 }}
      wide={showCancelReasonForm}
    >
      <ModalBody wide={showCancelReasonForm}>
        {showCancelReasonForm && (
          <CancelReasonsForm
            triggerType="Delete Account"
            selectedReasons={selectedReasons}
            setSelectedReasons={setSelectedReasons}
          />
        )}
        <p style={{ paddingBottom: 15 }}>
          Your {account.name} Runn account will be{" "}
          <span className={styles.red}>permanently deleted</span>, along with
          all users and data. This cannot be undone.
        </p>
        <Input
          label={<DeleteInputLabel />}
          name="delete-account-confirmation"
          className={styles.red}
          value={deleteValue}
          onChange={handleValue}
          nofocus
          autoFocus
        />
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" disabled={isDeleting} onClick={handleClose} />
        <Tooltip
          content="Please select a reason and add details. Thank you!"
          disabled={!formNeedsToBeFilled}
        >
          <Button
            text="Delete Account"
            id="delete-account-button"
            intent="danger"
            loading={isDeleting}
            onClick={handleSubmit}
            outlined={false}
            disabled={
              deleteValue.toLowerCase() !== account.name.toLowerCase() ||
              isDeleting ||
              formNeedsToBeFilled
            }
          />
        </Tooltip>
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default DeleteAccountForm
