import { capitalize } from "lodash-es"

// If you are changing this also make sure to update the values used in rails (helpers/plan_helper.rb)
export const CHARGEBEE_PROFESSIONAL_TRIAL_PRICE_ITEM_ID =
  "runn-professional-USD-monthly"
export const CHARGEBEE_STARTER_TRIAL_PRICE_ITEM_ID = "runn-starter-USD-monthly"
export const CHARGEBEE_PLANS = {
  ["runn-free"]: "Free",
  ["runn-starter"]: "Starter",
  ["runn-professional"]: "Professional",
  ["runn-premium"]: "Premium",
  ["runn-enterprise"]: "Enterprise",
}

export const CHARGEBEE_STATUSES = {
  active: "Active",
  non_renewing: "Non-Renewing",
  in_trial: "In Trial",
  paused: "Paused",
  cancelled: "Cancelled",
}

export const PROFESIONAL_PLAN_USD_MONTHLY_AMOUNT = 14

export const CHARGEBEE_AUTO_COLLECTION_STATUSES = {
  on: true,
  off: false,
}

type FeatureDetails = {
  name: string
}

type ChargebeeFeatureId =
  | "project-templates"
  | "custom-fields"
  | "account-views"
  | "workstreams"
  | "people"
  | "tags"
  | "api"
  | "timesheets"
  | "access-all-features"
  | "resourcing-requests"
  | "history-logs"
  | "integrations"
  | "csv"
  | "saml"

export type ChargebeeUIFeatureId = Exclude<
  ChargebeeFeatureId,
  "access-all-features"
>

export enum ChargebeeFeatures {
  projectTemplates = "project-templates",
  customFields = "custom-fields",
  accountViews = "account-views",
  workstreams = "workstreams",
  people = "people",
  api = "api",
  timesheets = "timesheets",
  resourcingRequests = "resourcing-requests",
  historyLogs = "history-logs",
  integrations = "integrations",
  csv = "csv",
  saml = "saml",
}

export const features: {
  [key in ChargebeeUIFeatureId]: FeatureDetails
} = {
  tags: {
    name: "Tags",
  },
  "project-templates": {
    name: "Project Templates",
  },
  "custom-fields": {
    name: "Custom Fields",
  },
  "account-views": {
    name: "Views",
  },
  workstreams: {
    name: "Workstreams",
  },
  people: {
    name: "People",
  },
  api: {
    name: "API",
  },
  timesheets: {
    name: "Timesheets/Actuals",
  },
  "resourcing-requests": {
    name: "Resourcing requests",
  },
  "history-logs": {
    name: "Activity logs",
  },
  integrations: {
    name: "Integrations",
  },
  csv: {
    name: "CSV",
  },
  saml: {
    name: "SSO (SAML)",
  },
}

/**
 * Is worth noting that the details here are not the source of truth and are
 * only used to compare features between plans within the app itself
 * If you are changing this also make sure to update the values used in rails (helpers/plan_helper.rb)
 */
export const featuresForPlans: {
  [key: string]: {
    [planKey in keyof typeof CHARGEBEE_PLANS]: boolean | number
  }
} = {
  "project-templates": {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  "custom-fields": {
    "runn-free": 0,
    "runn-starter": 0,
    "runn-professional": 5,
    "runn-premium": Infinity,
    "runn-enterprise": Infinity,
  },
  "account-views": {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  workstreams: {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": false,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  people: {
    "runn-free": 5,
    "runn-starter": Infinity,
    "runn-professional": Infinity,
    "runn-premium": Infinity,
    "runn-enterprise": Infinity,
  },
  api: {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  timesheets: {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  "resourcing-requests": {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  "history-logs": {
    "runn-free": 0,
    "runn-starter": 0,
    "runn-professional": 30,
    "runn-premium": 90,
    "runn-enterprise": 90,
  },
  integrations: {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  csv: {
    "runn-free": false,
    "runn-starter": true,
    "runn-professional": true,
    "runn-premium": true,
    "runn-enterprise": true,
  },
  saml: {
    "runn-free": false,
    "runn-starter": false,
    "runn-professional": false,
    "runn-premium": true,
    "runn-enterprise": true,
  },
}

/**
 *
 * Given a feature ID, return the plan prefixes that have access to that feature.
 * Example: ["runn-starter", "runn-professional"]
 */
export const getPlansForFeature = (featureId: ChargebeeFeatureId) => {
  return Object.keys(CHARGEBEE_PLANS).filter(
    (plan) => featuresForPlans[featureId][plan],
  )
}

/**
 *
 * Given a feature ID, return an oxford comma delimited string of the plans that
 * have access to that feature. Worth noting that both the self serve professional
 * and sales led professional are both shown to the user as if they were the
 * same "professional" plan.
 *
 * Example: Starter, Professional, and Premium
 */
export function getPlanNameListForFeature(input: {
  featureId: ChargebeeFeatureId
  options?: {
    delimiter?: string
    conjunction?: string
    skipConjunction?: boolean
    oxfordComma?: boolean
    filterValue?: any
  }
}): string {
  const { featureId, options = {} } = input

  const {
    delimiter = ",",
    conjunction = "and",
    skipConjunction = false,
    oxfordComma = false,
    filterValue,
  } = options

  const plans: string[] = Object.keys(CHARGEBEE_PLANS)
    .filter((plan) => {
      if (filterValue) {
        return featuresForPlans[featureId][plan] === filterValue
      }
      return !!featuresForPlans[featureId][plan]
    })
    .map((plan) => CHARGEBEE_PLANS[plan])

  if (plans.length === 1) {
    return plans[0]
  }

  if (skipConjunction) {
    return plans.join(`${delimiter} `)
  }

  return plans.reduce<string>((acc, plan, i) => {
    if (i === plans.length - 1) {
      if (oxfordComma) {
        acc += delimiter
      }

      acc += ` ${conjunction} `
    } else if (i !== 0) {
      acc += `${delimiter} `
    }

    acc += plan
    return acc
  }, "")
}

export const getBillingPeriod = (
  period: number,
  // "day" | "month" | "year",
  unit: string,
) => {
  // 15 years is considered "Free Forever"
  if (period === 15 && unit === "year") {
    return "Never"
  }

  if (period === 1) {
    return unit === "day" ? `daily` : `${capitalize(unit)}ly`
  }
  return `${period} ${unit}s`
}
