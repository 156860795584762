/**
 * @generated SignedSource<<82baafb2ecc2381d2a8df1606d732a0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateUserFavouritePeopleInput = {
  favouritePeople: ReadonlyArray<number>;
};
export type UserFavouritePeopleMutation$variables = {
  input: UpdateUserFavouritePeopleInput;
};
export type UserFavouritePeopleMutation$data = {
  readonly updateUserFavouritePeople: {
    readonly user: {
      readonly favourite_people: any;
      readonly id: number;
    } | null | undefined;
  };
};
export type UserFavouritePeopleMutation = {
  response: UserFavouritePeopleMutation$data;
  variables: UserFavouritePeopleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserFavouritePeoplePayload",
    "kind": "LinkedField",
    "name": "updateUserFavouritePeople",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "favourite_people",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFavouritePeopleMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserFavouritePeopleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7d18e4abe56d5cf86b85b7de67f8065f",
    "id": null,
    "metadata": {},
    "name": "UserFavouritePeopleMutation",
    "operationKind": "mutation",
    "text": "mutation UserFavouritePeopleMutation(\n  $input: UpdateUserFavouritePeopleInput!\n) {\n  updateUserFavouritePeople(input: $input) {\n    user {\n      id\n      favourite_people\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b21d33d210dba471eb5617a00907460";

export default node;
