import { AnalyticsBrowser } from "@segment/analytics-next"

import { getEnvInfo } from "./environment"
import { captureEvent } from "./error-helpers"

// Works around static binding issues: This gets executed
// before the app had a chance to assign the window object.
const getAnalytics = (): ReturnType<typeof createAnalytics> =>
  (window as any).runnAnalytics

// Normalised "path" to aid analytics that don't understand them (like Mixpanel).
const getNormalizedPath = (): string => {
  // Replace Rails-style edit links with IDs.
  // There's no good way to tell apart hashids from controller actions.
  const path = (window as any).location.pathname
    // "users/e193c1/edit" -> "users/edit"
    // "users/e193c1" -> "users/edit"
    .replace(
      /(projects|people|clients|rate_cards|roles|tags|teams|users)\/.+/,
      "$1/edit",
    )

  // Filters out user-specific path properties and most query params.
  // This is an important security mechanism, e.g. sanitising invitation query parameters.
  const allowedKeys = ["name", "show"]
  const params = new URLSearchParams(window.location.search)
  const filteredParams = new URLSearchParams()
  allowedKeys.forEach((param) => {
    if (params.has(param)) {
      filteredParams.append(param, params.get(param) ?? "")
    }
  })

  return filteredParams.toString()
    ? `${path}?${filteredParams.toString()}`
    : path
}

type Config = {
  token: string
  userId?: string
  accountId?: string
  organizationId?: string
  defaultProps?: Record<string, any>
}

// Lightweight wrapper to minimise wire-up inside consumers,
// and keep vendor-specific implementation out of them.
// By using a factory function,
// we retain the ability to get the config from async calls throughout app boot.
// This is purposely simple (no adapters/plugins) for now.
export const createAnalytics = (config?: Config) => {
  const isConfigured = Boolean(config)
  const envInfo = getEnvInfo(window.location.href)
  const segment = new AnalyticsBrowser()

  if (config) {
    // EU endpoint gets configured via the Segment UI settings,
    // see https://segment.com/docs/guides/regional-segment/
    segment.load({
      writeKey: config?.token,
    })
    segment.debug(envInfo.env !== "production")

    // Identify the user (or generate an identifier for null users).
    // The default properties should take care of the group association
    if (config.userId) {
      void segment.identify(config.userId, config.defaultProps)
      void segment.group(config.accountId, {
        organization_id: config.organizationId,
        group_key: "account_id",
      })
      void segment.group(config.organizationId, {
        group_key: "organization_id",
      })
    }

    // Events automatically contain the URL path
    const path = getNormalizedPath()
    void segment.page(path, {
      ...config.defaultProps,
      account_id: config.accountId,
      organization_id: config.organizationId,
      url: path, // Segment convention
      normalized_path: path, // Mixpanel legacy convention
    })
  }

  return {
    identify: (userId: string, traits?: Record<string, any>) => {
      if (!isConfigured) {
        return
      }

      void segment.identify(userId, {
        account_id: config.accountId,
        organization_id: config.organizationId,
        ...traits,
      })
    },
    group: (groupId: string, traits?: Record<string, any>) => {
      if (!isConfigured) {
        return
      }

      void segment.group(groupId, {
        account_id: config.accountId,
        organization_id: config.organizationId,
        ...traits,
      })
    },
    track: (event: string, props?: Record<string, any>) => {
      if (!isConfigured) {
        return
      }

      // We also track default properties because Mixpanel isn't great at joining user/account properties with events for analytics
      // TODO Incorporate page context
      void segment.track(event, {
        ...props,
        ...config.defaultProps,
        account_id: config.accountId,
        organization_id: config.organizationId,
      })

      // Track in application error monitoring for additional context
      // in case an error is caused soon after this event
      captureEvent({ event, ...(props || {}) }, "info")
    },
    page: (name: string, props?: Record<string, any>) => {
      if (!isConfigured) {
        return
      }

      // Legacy support for custom property (needed for consistent Mixpanel reporting)
      void segment.page(name, {
        normalized_path: getNormalizedPath(),
        ...props,
        ...config.defaultProps,
        account_id: config.accountId,
        organization_id: config.organizationId,
      })
    },
  }
}

export const identify = (userId: string, traits?: Record<string, any>) =>
  getAnalytics().identify(userId, traits)

export const group = (groupId: string, traits?: Record<string, any>) =>
  getAnalytics().group(groupId, traits)

// See naming conventions at https://www.notion.so/runn/UPDATE-Metrics-and-Analytics-c508403d68be4044abf4f5b3413c02c8?pvs=4#36e35372121046c59e09006c6c25d2c7
export const track = (event: string, props?: Record<string, any>) =>
  getAnalytics().track(event, props)

export const page = (name: string, props?: Record<string, any>) =>
  getAnalytics().page(name, props)

export default { createAnalytics, identify, track }
