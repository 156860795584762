import * as React from "react"

const Settings = (
  props: React.SVGProps<SVGSVGElement> & { color?: string },
) => {
  const { color, ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
      {...rest}
    >
      <path
        fill={color || "white"}
        d="M.716 10.694a.69.69 0 0 1-.51-.208A.694.694 0 0 1 0 9.978c0-.2.069-.37.206-.507a.693.693 0 0 1 .51-.205h2.626c.202 0 .372.068.511.205a.682.682 0 0 1 .209.507c0 .2-.07.37-.209.508a.697.697 0 0 1-.51.208H.715Zm0-7.96a.694.694 0 0 1-.51-.205A.687.687 0 0 1 0 2.022c0-.2.069-.37.206-.508a.69.69 0 0 1 .51-.208h5.253c.201 0 .372.07.51.208a.69.69 0 0 1 .21.508c0 .2-.07.37-.21.507a.7.7 0 0 1-.51.205H.716ZM6.03 12a.697.697 0 0 1-.51-.207.69.69 0 0 1-.209-.509V8.671a.701.701 0 0 1 .72-.712c.201 0 .37.069.508.205a.686.686 0 0 1 .207.507v.595h4.537c.203 0 .373.068.51.205a.687.687 0 0 1 .206.507c0 .2-.069.37-.206.508a.69.69 0 0 1-.51.208H6.747v.59c0 .201-.069.37-.206.509a.69.69 0 0 1-.51.207ZM3.342 8.018a.695.695 0 0 1-.509-.204.686.686 0 0 1-.206-.508v-.594H.716a.694.694 0 0 1-.51-.205A.687.687 0 0 1 0 6c0-.202.069-.37.206-.507a.694.694 0 0 1 .51-.205h1.91v-.594c0-.202.07-.371.206-.508a.694.694 0 0 1 .51-.204c.202 0 .372.068.511.204a.682.682 0 0 1 .209.508v2.612c0 .202-.07.371-.209.508a.701.701 0 0 1-.51.204Zm2.69-1.306a.701.701 0 0 1-.512-.205A.682.682 0 0 1 5.312 6a.701.701 0 0 1 .72-.712h5.252c.203 0 .373.068.51.205A.687.687 0 0 1 12 6c0 .202-.069.37-.206.507a.694.694 0 0 1-.51.205H6.031ZM8.657 4.04a.701.701 0 0 1-.511-.204.682.682 0 0 1-.208-.507V.716c0-.201.069-.37.208-.509A.697.697 0 0 1 8.658 0a.69.69 0 0 1 .51.207.693.693 0 0 1 .205.509v.59h1.912a.69.69 0 0 1 .51.208.694.694 0 0 1 .205.508c0 .2-.069.37-.206.507a.693.693 0 0 1-.51.205h-1.91v.595c0 .201-.07.37-.206.507a.694.694 0 0 1-.51.204Z"
      />
    </svg>
  )
}

export default Settings
