/* eslint-disable prefer-const */

/* ************************************************************************
 *  Using global vars need to be carefully considered in react
 *  and should generally be avoided.
 *  When a global var changes, react will not render.
 *  Therefore it should only be used where it you are looking
 *  at the global var only on the first render
 *  or if you use it in a function that is called by an event listener
 *  such as an onClick handler
 * ************************************************************************/

/* ************************************************************************
 *  INTERACTING_WITH_PILL is used because we do not want pills and calendar
 *  days to re-render when this changes. We just want to check on function
 *  calls like onHover or onClick. This stops hundreds of components
 *  re-rendering every time you mouse the mouse to a new cell/pill.
 * ************************************************************************/
import { dateHelpers } from "@runn/calculations"
import { User } from "@runn/permissions"
import { startOfISOWeek, subMonths } from "date-fns"

import { Option } from "./types/helpers"

declare global {
  type Rollbar = {
    error: (error: string | Error, data?: Record<string, unknown>) => void
    captureEvent: (
      data: Record<string, unknown>,
      level: "debug" | "info" | "warning" | "error" | "critical",
    ) => void
  }
  type Chargebee = {
    init: (config: { site: string }) => any
    inited: boolean
    openCheckout: (options: { hostedPage: string; close: () => void }) => any
    getInstance: () => any
    createChargebeePortal: () => any
    getPortalSections: () => {
      SUBSCRIPTION_DETAILS: "sub_details"
      SUBSCRIPTION_CANCELLATION: "sub_cancel"
      EDIT_SUBSCRIPTION: "edit_subscription"
      VIEW_SCHEDULED_CHANGES: "scheduled_changes"
      ACCOUNT_DETAILS: "account_details"
      EDIT_ACCOUNT_DETAILS: "portal_edit_account"
      ADDRESS: "portal_address"
      EDIT_BILLING_ADDRESS: "portal_edit_billing_address"
      EDIT_SHIPPING_ADDRESS: "portal_edit_shipping_address"
      EDIT_SUBSCRIPTION_CUSTOM_FIELDS: "portal_edit_subscription_cf"
      PAYMENT_SOURCES: "portal_payment_methods"
      ADD_PAYMENT_SOURCE: "portal_add_payment_method"
      EDIT_PAYMENT_SOURCE: "portal_edit_payment_method"
      VIEW_PAYMENT_SOURCE: "portal_view_payment_method"
      CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION: "portal_choose_payment_method"
      BILLING_HISTORY: "portal_billing_history"
    }
  }

  type Intercom = (action: string) => void

  type Userflow = {
    init: (token: string) => void
    track?: (
      eventName: string,
      props?: Record<string, unknown>,
      opts?: Record<string, unknown>,
    ) => void
    identify: (
      userId: string,
      props: Record<string, unknown>,
      opts?: Record<string, unknown>,
    ) => void
    group: (
      accountId: string,
      props: Record<string, unknown>,
      opts?: Record<string, unknown>,
    ) => void
    setResourceCenterLauncherHidden: (hidden: boolean) => void
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Rollbar?: Rollbar
    Intercom?: Intercom
    userflow?: Userflow
    Chargebee?: Chargebee
    CHARGEBEE_SITE: string
  }
}

export let INTERACTING_WITH_PILL = false

export const PRICING_MODELS = [
  { value: "tm", label: "Time and Materials" },
  { value: "fp", label: "Fixed Price" },
  { value: "nb", label: "Non-Billable" },
] as const

export type PricingModelOptions = (typeof PRICING_MODELS)[number]["value"]
export type PricingModelSelection = (typeof PRICING_MODELS)[number]

export const STATUS_OPTIONS = [
  { value: true, label: "Confirmed" },
  { value: false, label: "Tentative" },
] as const

export const INSIGHT_WEEKS_LENGTH = 4

export const RATE_TYPES = [
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
] as const

export type RateTypeOptions = (typeof RATE_TYPES)[number]["value"]
export type RateTypeSelection = (typeof RATE_TYPES)[number]

export const ACTIVE_FILTER_OPTIONS = [
  { value: "all", label: "All" },
  { value: true, label: "Active" },
  { value: false, label: "Archived" },
] as const

export const DEFAULT_ACTIVE_FILTER_OPTION = ACTIVE_FILTER_OPTIONS[1]

// We load 1 month of previous data to ensure fast navigation
// and better handle conflicts with past assignments
export const PLANNER_INITIAL_DATE = dateHelpers.formatToRunnDate(
  subMonths(startOfISOWeek(new Date()), 1),
)

export const RELAY_ASSIGNMENT_LOCATION = `assignments(where:{"end_date_iso":{"_gte":"${PLANNER_INITIAL_DATE}"}})`
export const RELAY_TIMEOFF_LOCATION = `time_offs(where:{"end_date_iso":{"_gte":"${PLANNER_INITIAL_DATE}"}})`

export const CANCEL_REASONS = [
  {
    reason: "Runn is missing features I need",
    placeholder: "What features would you like to see?",
  },
  {
    reason: "Runn is too expensive",
    placeholder: "What pricing would you think is fair?",
  },
  {
    reason: "We aren't using Runn",
    placeholder: "No worries. Are you going to use something else instead?",
  },
  {
    reason: "Runn wasn't what I expected",
    placeholder: "What type of product were you after?",
  },
  {
    reason: "We're going to use another product ",
    placeholder: "No worries. What product did you decide to go with and why?",
  },
  {
    reason: "I had technical issues",
    placeholder:
      "We're sorry to hear that. Can you tell us more about the issues?",
  },
  { reason: "This is a duplicate account", placeholder: "" },
  { reason: "Other", placeholder: "We'd love to hear your feedback" },
] as const

export const ACCOUNT_ID = (window as any).__RAILS_ACCOUNT_ID__ || 0

export const USER_TYPES = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "contributor", label: "Contributor" },
] as const

export type UserTypeOption = (typeof USER_TYPES)[number]["value"]

export const FINANCIAL_PERMISSIONS = [
  { value: "all", label: "All" },
  { value: "restricted", label: "Restricted" },
  { value: "none", label: "None" },
] as const

export type FinancialPermissionsOption =
  (typeof FINANCIAL_PERMISSIONS)[number]["value"]

export const MANAGE_ACCOUNT_PERMISSIONS = [
  { value: true, label: "On" },
  { value: false, label: "Off" },
] as const

export const VIEW_PLANNER_PERMISSIONS = [
  { value: true, label: "Planner and Timesheets" },
  { value: false, label: "Timesheets Only" },
] as const

export type ViewPlannerPermissionsOption =
  (typeof VIEW_PLANNER_PERMISSIONS)[number]["value"]

export type ManageAccountPermissionsOption =
  (typeof MANAGE_ACCOUNT_PERMISSIONS)[number]["value"]

export const MANAGE_PERMISSIONS: (Pick<
  User["permissions"],
  "manage_projects" | "manage_people" | "add_all_people_to_projects"
> &
  Option)[] = [
  {
    manage_projects: "all",
    manage_people: "all",
    add_all_people_to_projects: true,
    value: "all_projects_all_people",
    label: "All Projects, All People",
  },
  {
    manage_projects: "all",
    manage_people: "restricted",
    add_all_people_to_projects: true,
    value: "all_projects_restricted_people",
    label: "All Projects, Restricted People",
  },
  {
    manage_projects: "restricted",
    manage_people: "all",
    add_all_people_to_projects: true,
    value: "restricted_projects_all_people",
    label: "Restricted Projects, All People",
  },
  {
    manage_projects: "restricted",
    manage_people: "restricted",
    add_all_people_to_projects: true,
    value: "restricted_projects_restricted_people",
    label: "Restricted Projects, Restricted People",
  },
  {
    manage_projects: "all",
    manage_people: "none",
    add_all_people_to_projects: false,
    value: "all_projects_none_people",
    label: "All Projects, View People",
  },
  {
    manage_projects: "none",
    manage_people: "all",
    add_all_people_to_projects: true,
    value: "none_projects_all_people",
    label: "View Projects, All People",
  },
  {
    manage_projects: "restricted",
    manage_people: "none",
    add_all_people_to_projects: false,
    value: "restricted_projects_none_people",
    label: "Restricted Projects, View People",
  },
  {
    manage_projects: "none",
    manage_people: "restricted",
    add_all_people_to_projects: true,
    value: "none_projects_restricted_people",
    label: "View Projects, Restricted People",
  },
  {
    manage_projects: "none",
    manage_people: "none",
    add_all_people_to_projects: false,
    value: "none_projects_none_people",
    label: "View projects, View people",
  },
] as const

export type ManagerPermissionsOption =
  (typeof MANAGE_PERMISSIONS)[number]["value"]

export type ManageProjectsPermissionsOption =
  (typeof MANAGE_PERMISSIONS)[number]["manage_projects"]

export type ManagePeoplePermissionsOption =
  (typeof MANAGE_PERMISSIONS)[number]["manage_people"]

export const EMPLOYMENT_OPTIONS = [
  { value: "employee", label: "Employee" },
  { value: "contractor", label: "Contractor" },
] as const

export type EmploymentOption = (typeof EMPLOYMENT_OPTIONS)[number]["value"]

export const REPORTS_LIST = [
  {
    name: "Project Report",
    type: "projects",
    navigationName: "Overview",
    description: "View overall KPIs",
    v2: true,
  },
  {
    name: "People Report",
    type: "people",
    navigationName: "Overview",
    description: "View overall KPIs",
    v2: true,
  },
  {
    name: "Project Overview",
    type: "projects",
    description: "View overall KPIs",
    navigationName: "Overview",
    v2: false,
  },
  {
    name: "Project Period",
    type: "projects",
    description: "View periodical KPIs",
    navigationName: "Period",
    v2: false,
  },
  {
    name: "Project Cumulative",
    type: "projects",
    description: "View cumulative KPIs",
    navigationName: "Cumulative",
    v2: false,
  },
  {
    name: "People Explorer",
    type: "people",
    description: "View people KPIs",
    navigationName: "Explorer",
    v2: false,
  },
  {
    name: "People Variance",
    type: "people",
    description: "View difference between scheduled work and timesheets",
    navigationName: "Variance",
    v2: false,
  },
  {
    name: "Missing Timesheets",
    type: "people",
    description: "Find missing timesheets",
    navigationName: "Timesheets",
    v2: false,
  },
  {
    name: "People Utilization",
    type: "people",
    description: "View each person's utilization",
    navigationName: "Utilization",
    v2: false,
  },
  {
    name: "Project Milestones",
    type: "projects",
    description: "View milestones from all projects",
    navigationName: "Milestones",
    v2: false,
  },
] as const

export type ReportName = (typeof REPORTS_LIST)[number]["navigationName"]
export type ReportTitle = (typeof REPORTS_LIST)[number]["name"]

const CURRENT_YEAR = new Date().getFullYear()
export const HOLIDAYS_YEARS = {
  CURRENT_YEAR,
  SECOND_YEAR: CURRENT_YEAR + 1,
  THIRD_YEAR: CURRENT_YEAR + 2,
}

export const HOLIDAY_TYPES = {
  PUBLIC: "public",
  CUSTOM: "custom",
}

export const HOLIDAYS_DATE_RANGES = {
  minDate: `${HOLIDAYS_YEARS.CURRENT_YEAR}-01-01`,
  maxDate: `${HOLIDAYS_YEARS.THIRD_YEAR}-12-31`,
}
// NOTE - these are current plans and do not include the plans as part of the
// feature subscripion work. That is stored in `plansAndFeatures.ts`
export const SUBSCRIPTIONS = {
  free_plan_limit: 5,
  free_plan_id: "runn-free",
  pro_plan_id: "runn-pro-monthly",
}

export const RATE_CARD_DESCRIPTIONS = {
  standard: "Default rates for new projects",
  internal: "For internal and non-billable projects. All roles are set to $0",
}

// prettier-ignore
export const CURRENCIES = {
  "$": 'Dollar',
  "€": 'Euro',
  "£": 'Pound',
  "₣": 'Franc',
  "CHF": 'Franc (Swiss)',
  "฿": 'Baht',
  "د.إ": 'Dirham',
  "₫": 'Dồng',
  "Ft": 'Forint',
  "Rp": 'Rupiah',
  "Kč": 'Koruna',
  "kr": 'Kroner',
  "₺": 'Lira',
  "₦": 'Naira',
  "₱": 'Peso (Philippine)',
  "R": 'Rand',
  "R$": 'Real',
  "RM": 'Ringgit',
  "₽": 'Ruble',
  "Rs": 'Rupee',
  "₹": 'Rupee (Indian)',
  "₪": 'Shekel',
  "৳": 'Taka',
  "₩": 'Won',
  "¥": 'Yen/Yuan',
  "元": 'Yuan',
  "zł ": 'Zloty',
}

// Needs to contain at least all currencies that we charge in
export const CURRENCY_SYMBOLS_MAP = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  AUD: "$",
  CAD: "$",
  NZD: "$",
}

export const relayProjectLocation = (filters: string) =>
  `projects(where:${filters})`
export const relayPeopleLocation = (filters: string) =>
  `people(where:${filters})`

export const MAX_SKILL_NAME_LENGTH = 200

export default {
  INTERACTING_WITH_PILL,
  PRICING_MODELS,
  STATUS_OPTIONS,
  INSIGHT_WEEKS_LENGTH,
  RATE_TYPES,
  ACTIVE_FILTER_OPTIONS,
  PLANNER_INITIAL_DATE,
  CANCEL_REASONS,
  ACCOUNT_ID,
  USER_TYPES,
  FINANCIAL_PERMISSIONS,
  EMPLOYMENT_OPTIONS,
  REPORTS_LIST,
  HOLIDAYS_YEARS,
  HOLIDAY_TYPES,
  HOLIDAYS_DATE_RANGES,
  SUBSCRIPTIONS,
  RATE_CARD_DESCRIPTIONS,
  CURRENCIES,
}
