/**
 * @generated SignedSource<<8bc0d65d3daea41c3cb6662b32aaa479>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectsPanelQuery$variables = Record<PropertyKey, never>;
export type ProjectsPanelQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly id: number;
      readonly projects: ReadonlyArray<{
        readonly id: number;
        readonly name: string;
      }>;
    };
    readonly id: number;
  } | null | undefined;
};
export type ProjectsPanelQuery = {
  response: ProjectsPanelQuery$data;
  variables: ProjectsPanelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  },
                  "is_template": {
                    "_eq": false
                  }
                }
              }
            ],
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "projects",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": "projects(where:{\"active\":{\"_eq\":true},\"is_template\":{\"_eq\":false}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsPanelQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectsPanelQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "adb8f2e4d1814bd60758fadddee2ecfb",
    "id": null,
    "metadata": {},
    "name": "ProjectsPanelQuery",
    "operationKind": "query",
    "text": "query ProjectsPanelQuery {\n  current_user {\n    id\n    account {\n      id\n      projects(where: {is_template: {_eq: false}, active: {_eq: true}}) {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01693c4500934f41d87e3e3e0cf5444a";

export default node;
