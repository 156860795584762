import { Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./HelpTooltip.module.css"

import { Help, Launch } from "~/common/react-icons"

type Props = {
  text: string
  linkText: string
  url: string
}

const HelpTooltip = ({ text, linkText, url }: Props) => {
  return (
    <Tooltip
      interactionKind="hover"
      content={
        <>
          {text}{" "}
          <a href={url} target="_blank" className={styles.url} rel="noopener">
            {linkText}
          </a>{" "}
          <Launch className={styles.inlineIcon} fill="white" />
        </>
      }
      placement="top"
      hoverCloseDelay={300}
      className={styles.tooltip}
    >
      <Help className={styles.inlineIcon} color="var(--midnight)" />
    </Tooltip>
  )
}

export default HelpTooltip
