import { useFeature } from "flagged"
import { useContext } from "react"

import EntitlementsContext from "./EntitlementsContext"
import { checkEntitlementQuantity, checkEntitlementSwitch } from "./utils"

/**
 * Gets the current account's subscription plan
 */

export const usePlan = () => {
  const { plan } = useContext(EntitlementsContext)

  return plan
}

export const useIsInTrial = () => {
  const { isInTrial } = useContext(EntitlementsContext)
  return isInTrial
}

export const useIsInFreePlan = () => {
  const { plan } = useContext(EntitlementsContext)

  return plan?.includes("runn-free") ?? false
}

export const useIsInStarterPlan = () => {
  const { plan } = useContext(EntitlementsContext)

  return plan?.includes("runn-starter") ?? false
}

export const useIsInProfessionalPlan = () => {
  const { plan } = useContext(EntitlementsContext)

  return plan?.includes("runn-professional") ?? false
}

export const useIsInPremiumPlan = () => {
  const { plan } = useContext(EntitlementsContext)

  return plan?.includes("runn-premium") ?? false
}

export const useIsLegacyProPlan = () => {
  const { plan } = useContext(EntitlementsContext)

  // The legacy professional plan starts with `runn-pro`, not `runn-professional`.
  // We can't do a check just for "runn-pro" because the current professional
  // plan also starts with "runn-pro" so we need to check for the trailing "-".
  return plan?.includes("runn-pro-") ?? false
}

export const useIsInEnterprisePlan = (
  accountSubscriptionType: string | null | undefined,
) => {
  const { plan } = useContext(EntitlementsContext)

  // accountSubscriptionType is on the account table and is a legacy way of
  // stating that a customer is an "enterprise" customer. We need to check
  // this first because it supersedes the entitlement check below
  if (accountSubscriptionType === "enterprise") {
    return true
  }

  return plan?.includes("runn-enterprise") ?? false
}

export const useShowUpsellingSection = () => {
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const isInTrial = useIsInTrial()
  const isFreePlan = useIsInFreePlan()
  const showUpsellingSection = entitlementsEnabled && (isInTrial || isFreePlan)
  return showUpsellingSection
}

/**
 * Gets all feature entitlements for the current account's subscription. If the
 * account does not have the flag enabled then we return an empty array
 */
export const useEntitlements = () => {
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const { entitlements } = useContext(EntitlementsContext)

  if (!entitlementsEnabled) {
    return []
  }

  return entitlements || []
}

/**
 * Gets a single feature entitlements for the current account's subscription by the
 * id of the feature. If the account does not have the flag enabled then we
 * return null. If the feature id does not exist in the entitlements then we return
 * null.
 */
export const useEntitlement = (featureId: string) => {
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const { entitlements } = useContext(EntitlementsContext)

  if (!entitlementsEnabled) {
    return null
  }

  return (
    (entitlements || []).find(
      (entitlement) => entitlement.featureId === featureId,
    ) || null
  )
}

/**
 * Gets a single feature entitlement's switch value for the current account's
 * subscription by the id of the feature. If the account does not have the feature
 * then we return false. If the feature id does not exist in the entitlements then
 * we return false. If the account does not have the flag enabled then we return
 * true (so any account without the flag enabled will have access to the feature).
 */
export const useEntitlementSwitch = (featureId: string) => {
  const entitlementsEnabled = useFeature("subscription_entitlements")

  const { entitlements } = useContext(EntitlementsContext)

  if (!entitlementsEnabled || !entitlements) {
    return true
  }

  return checkEntitlementSwitch(entitlements, featureId)
}

/**
 * Gets a single feature entitlement's quantity value for the current account's
 * subscription by the id of the feature. If the account does not have the feature enabled
 * then we return 0. If the feature id does not exist in the entitlements then
 * we return 0. If the account does not have the flag enabled then we return
 * Infinite
 */
export const useEntitlementQuantity = (featureId: string) => {
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const { entitlements } = useContext(EntitlementsContext)

  if (!entitlementsEnabled || !entitlements) {
    return Infinity
  }

  return checkEntitlementQuantity(entitlements, featureId)
}
