import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}
const SquareFace = (props: Props) => {
  const { size = 18, color = "var(--midnight)", alt } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM11.875 8.33333C11.875 9.36667 11.0333 10.2083 10 10.2083C8.96667 10.2083 8.125 9.36667 8.125 8.33333C8.125 7.3 8.96667 6.45833 10 6.45833C11.0333 6.45833 11.875 7.3 11.875 8.33333ZM10 11.6667C11.25 11.6667 13.75 12.2917 13.75 13.5417V14.1667H6.25V13.5417C6.25 12.2917 8.75 11.6667 10 11.6667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333Z"
        fill={color}
      />
    </svg>
  )
}

export default SquareFace
