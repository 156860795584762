/**
 * @generated SignedSource<<10961dbbb71de2dd6d42188e6aa457c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntitlementsProvider_billing$data = {
  readonly entitlements: ReadonlyArray<{
    readonly featureId: string;
    readonly featureType: string;
    readonly value: string;
  }> | null | undefined;
  readonly subscription: {
    readonly id: string;
    readonly isInTrial: boolean;
    readonly plan: {
      readonly itemPriceId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "EntitlementsProvider_billing";
};
export type EntitlementsProvider_billing$key = {
  readonly " $data"?: EntitlementsProvider_billing$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntitlementsProvider_billing">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntitlementsProvider_billing",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInTrial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingSubscriptionItem",
          "kind": "LinkedField",
          "name": "plan",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemPriceId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingSubscriptionEntitlement",
      "kind": "LinkedField",
      "name": "entitlements",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "featureId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "featureType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Billing",
  "abstractKey": null
};

(node as any).hash = "3b5119983ea7b3cb05e40eb03787d7ce";

export default node;
