import cc from "classcat"
import React from "react"

import styles from "./Callout.module.css"

type Props = {
  className?: string
  children: React.ReactNode
  icon?: React.ReactNode
}

const Callout = ({ className, children, icon }: Props) => {
  return (
    <div className={cc([styles.callout, className])}>
      {icon}
      {children}
    </div>
  )
}

export default Callout
