import { Icon } from "@blueprintjs/core"
import React from "react"
import Dropzone from "react-dropzone"
import {
  Control,
  Controller,
  UseFormSetError,
  useController,
  useWatch,
} from "react-hook-form"

import styles from "./Panel.module.css"

import { buildImageUrl } from "~/helpers/image"
import { formatNameAsInitials } from "~/helpers/person"

import Avatar from "~/common/Avatar"
import Input from "~/common/Input"
import Callout from "~/common/Text/Callout"
import SupportingText from "~/common/Text/SupportingText"
import Button from "~/common/buttons/Button"
import { Warning } from "~/common/react-icons"

import { Mode, User, UserForm } from "../userForm"

type Props = {
  control: Control<UserForm>
  mode: Mode
  setError: UseFormSetError<UserForm>
  user: User
}

const PersonalDetailsPanel = ({ control, mode, setError, user }: Props) => {
  const {
    field: { onChange: onAvatarChange, value: avatarDataURL },
  } = useController({
    name: "avatar",
    control,
  })

  useWatch({ control, name: ["avatar"] })

  const onDrop = (files) => {
    if (files && files[0]) {
      const reader = new FileReader()
      const size = files[0].size

      if (size > 100000) {
        setError("avatar", {
          type: "custom",
          message:
            "Image size must be less than 100kb. Please resize your image and try again. Recommended image size is 100x100 pixels.",
        })
        return
      }

      reader.onload = () => {
        onAvatarChange(reader.result)
      }

      reader.readAsDataURL(files[0])
    }
  }

  return (
    <div className={styles.panel}>
      {mode === Mode.EDIT && (
        <>
          <div className={styles.uploadSection}>
            <div className={styles.avatarContainer}>
              <Button
                icon={<Icon icon="trash" size={10} />}
                onClick={() => onAvatarChange(null)}
                className={styles.trashButton}
                intent="none"
              />
              <Avatar
                email={user.email}
                initials={formatNameAsInitials(user.first_name, user.last_name)}
                avatar={
                  avatarDataURL !== undefined
                    ? avatarDataURL
                    : buildImageUrl(user.image_key)
                }
                size={50}
              />
            </div>
            <div className={styles.column}>
              <Dropzone onDrop={onDrop} maxFiles={1}>
                {({ getRootProps, getInputProps }) => (
                  <div className={styles.dropZone} {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                      multiple={false}
                    />
                    <div className={styles.uploadText}>
                      <Icon icon="media" />
                      <span>
                        <a href="#">Upload</a> or drop your file here.
                      </span>
                    </div>
                  </div>
                )}
              </Dropzone>
              <span>
                Accepted file types: .jpg .jpeg .png .svg | 100kb limit
              </span>
            </div>
          </div>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <div>
                <Input
                  className={styles.input}
                  label="First Name"
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <div>
                <Input
                  className={styles.input}
                  label="Last Name"
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
          />
        </>
      )}

      <Controller
        control={control}
        name="email"
        render={({
          field: { onBlur, onChange, value },
          fieldState: { error },
        }) => (
          <div>
            <Input
              className={styles.input}
              label="Email"
              onBlur={onBlur} // Required for the validation mode "onTouched" to work correctly
              onChange={onChange}
              value={value}
              disabled={mode === Mode.EDIT}
              dataTest="email-input"
              type="email"
            />
            {error && (
              <SupportingText intent="danger">{error.message}</SupportingText>
            )}
          </div>
        )}
      />
      {mode === Mode.INVITE && (
        <Callout icon={<Warning className={styles.warningIcon} />}>
          <span>
            {"To "}
            <strong>bulk add</strong>
            {" users with different permission types we recommend using our "}
            <a
              href={"/import_v2"}
              target="_blank"
              className={styles.blueUrl}
              rel="noopener"
            >
              {"CSV import"}
            </a>
            {" functionality."}
          </span>
        </Callout>
      )}
    </div>
  )
}

export default PersonalDetailsPanel
