import cc from "classcat"
import React from "react"

import styles from "./SupportingText.module.css"

type Props = {
  children: React.ReactNode
  intent?: "danger"
}

const SupportingText = ({ children, intent }: Props) => {
  return (
    <div className={styles.root}>
      <div
        className={cc([
          styles.line,
          {
            [styles.danger]: intent === "danger",
          },
        ])}
      />
      {children}
    </div>
  )
}

export default SupportingText
