import { graphql } from "react-relay"

import { InvitationRenewMutation } from "./__generated__/InvitationRenewMutation.graphql"
import { InvitationCreateMutation } from "~/mutations/__generated__/InvitationCreateMutation.graphql"

import { environment } from "~/store/hasura"

import { addLinkedRecord, commitMutationPromise } from "./helpers"
import * as relayids from "~/helpers/relayids"

import { showToast } from "~/containers/ToasterContainer"

const invitationRenewMutation = graphql`
  mutation InvitationRenewMutation($input: InvitationRenewInput!) {
    action_invitation_renew(input: $input) {
      invitation {
        id
        expires_at
      }
    }
  }
`

type InvitationRenewRelayOptions = {
  variables: InvitationRenewMutation["variables"]
}

export const invitationRenewRelay = async (
  options: InvitationRenewRelayOptions,
) => {
  const { variables } = options
  await commitMutationPromise<InvitationRenewMutation>(environment, {
    mutation: invitationRenewMutation,
    variables,
  })
  showToast({
    message: "Successfully re-sent invitation",
    type: "success",
  })
}

const invitationCreateMutation = graphql`
  mutation InvitationCreateMutation($input: InvitationCreateInput!) {
    action_invitation_create(input: $input) {
      invitation {
        id
        account_id
        email
        user_type
        fin_permission
        manage_projects_permission
        manage_people_permission
        add_all_people_to_projects_permission
        manage_account_permission
        view_planner_permission
        created_at
        expires_at
        manageable_projects_aggregate {
          aggregate {
            count
          }
        }
        manageable_people_aggregate {
          aggregate {
            count
          }
        }
      }
      account {
        id
        people {
          id
        }
        invitations {
          id
          email
        }
      }
      error
    }
  }
`

export const invitationCreateRelay = async (
  variables: InvitationCreateMutation["variables"],
) => {
  const { action_invitation_create } =
    await commitMutationPromise<InvitationCreateMutation>(environment, {
      mutation: invitationCreateMutation,
      variables,
      updater: (store, data) => {
        if (data.action_invitation_create.invitation) {
          const root = store.getRoot()
          const invitation = store.get(
            relayids.invitations.encode(
              data.action_invitation_create.invitation.id,
            ),
          )
          addLinkedRecord(root, "invitations", invitation)
        }
      },
    })

  const error = action_invitation_create.error
  if (error) {
    return {
      error: action_invitation_create.error,
    }
  }

  return {
    id: action_invitation_create.invitation.id,
  }
}
