/**
 * @generated SignedSource<<028ee942f4175314b194189707b19339>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type holidays_groups_set_input = {
  name?: string | null | undefined;
};
export type HolidaysGroupUpdateMutation$variables = {
  id: number;
  input: holidays_groups_set_input;
};
export type HolidaysGroupUpdateMutation$data = {
  readonly update_holidays_groups_by_pk: {
    readonly id: number;
    readonly name: string;
  } | null | undefined;
};
export type HolidaysGroupUpdateMutation = {
  response: HolidaysGroupUpdateMutation$data;
  variables: HolidaysGroupUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "input"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "holidays_groups",
    "kind": "LinkedField",
    "name": "update_holidays_groups_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HolidaysGroupUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HolidaysGroupUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4794d289f65c18bfc9971649c35fc07c",
    "id": null,
    "metadata": {},
    "name": "HolidaysGroupUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation HolidaysGroupUpdateMutation(\n  $id: Int!\n  $input: holidays_groups_set_input!\n) {\n  update_holidays_groups_by_pk(pk_columns: {id: $id}, _set: $input) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f4beea004dd4a11443fcfa4484b8bd2";

export default node;
