import { graphql } from "react-relay"
import { SelectorStoreUpdater } from "relay-runtime"

import {
  UserSelectedViewMutation,
  UserSelectedViewMutation$variables,
} from "./__generated__/UserSelectedViewMutation.graphql"
import {
  UserViewedWelcomeMutation,
  UserViewedWelcomeMutation$variables,
} from "./__generated__/UserViewedWelcomeMutation.graphql"
import {
  UserFavouritePeopleMutation,
  UserFavouritePeopleMutation$variables,
} from "~/mutations/__generated__/UserFavouritePeopleMutation.graphql"
import {
  UserFavouriteProjectsMutation,
  UserFavouriteProjectsMutation$variables,
} from "~/mutations/__generated__/UserFavouriteProjectsMutation.graphql"
import {
  UserPermissionsUpdateMutation,
  UserPermissionsUpdateMutation$data,
} from "~/mutations/__generated__/UserPermissionsUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"
import * as relayids from "~/helpers/relayids"

const userFavouritePeopleMutation = graphql`
  mutation UserFavouritePeopleMutation(
    $input: UpdateUserFavouritePeopleInput!
  ) {
    updateUserFavouritePeople(input: $input) {
      user {
        id
        favourite_people
      }
    }
  }
`

export const userFavouritePeopleUpdateRelay = (
  variables: UserFavouritePeopleMutation$variables,
) => {
  return commitMutationPromise<UserFavouritePeopleMutation>(environment, {
    mutation: userFavouritePeopleMutation,
    variables,
  })
}

const userFavouriteProjectsMutation = graphql`
  mutation UserFavouriteProjectsMutation(
    $input: UpdateUserFavouriteProjectsInput!
  ) {
    updateUserFavouriteProjects(input: $input) {
      user {
        id
        favourite_projects
      }
    }
  }
`

export const userFavouriteProjectsUpdateRelay = (
  variables: UserFavouriteProjectsMutation$variables,
) => {
  return commitMutationPromise<UserFavouriteProjectsMutation>(environment, {
    mutation: userFavouriteProjectsMutation,
    variables,
  })
}

const userViewedWelcomeMutation = graphql`
  mutation UserViewedWelcomeMutation($input: UpdateUserViewedWelcomeInput!) {
    updateUserViewedWelcome(input: $input) {
      user {
        id
        viewed_welcome
      }
    }
  }
`

export const userViewedWelcomeRelay = (
  variables: UserViewedWelcomeMutation$variables,
) => {
  return commitMutationPromise<UserViewedWelcomeMutation>(environment, {
    mutation: userViewedWelcomeMutation,
    variables,
  })
}

const userSelectedViewMutation = graphql`
  mutation UserSelectedViewMutation($input: UpdateUserSelectedViewInput!) {
    updateUserSelectedView(input: $input) {
      user {
        id
        view_id
        view {
          id
        }
      }
    }
  }
`

export const userViewUpdateRelay = (
  variables: UserSelectedViewMutation$variables,
) => {
  return commitMutationPromise<UserSelectedViewMutation>(environment, {
    mutation: userSelectedViewMutation,
    variables,
  })
}

const userPermissionsUpdateMutation = graphql`
  mutation UserPermissionsUpdateMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      account {
        id
      }
      user {
        id
        first_name
        last_name
        email
        permissions
        image_key
        manageable_people {
          id
          person_id
        }
        manageable_projects {
          id
          project_id
        }
      }
    }
  }
`

export const userPermissionsUpdateRelay = (
  variables: UserPermissionsUpdateMutation["variables"],
) => {
  const updater: SelectorStoreUpdater<UserPermissionsUpdateMutation$data> = (
    store,
  ) => {
    const updatedUser = store.getRootField("updateUser").getLinkedRecord("user")
    const currentAccountUser = store.get(
      relayids.currentAccountUser.encode(variables.input.userId),
    )

    if (currentAccountUser) {
      currentAccountUser.copyFieldsFrom(updatedUser)
      currentAccountUser.setValue(
        updatedUser.getLinkedRecords("manageable_people").length,
        "manageable_people_count",
      )
      currentAccountUser.setValue(
        updatedUser.getLinkedRecords("manageable_projects").length,
        "manageable_projects_count",
      )
    }
  }

  return commitMutationPromise<UserPermissionsUpdateMutation>(environment, {
    mutation: userPermissionsUpdateMutation,
    variables,
    updater,
  })
}
