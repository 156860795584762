/**
 * @generated SignedSource<<66513a8c1f1b31ca5907f1a7a6d9039f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateHolidaysGroupForm_viewer$data = {
  readonly holidays_groups: ReadonlyArray<{
    readonly country_code: string;
    readonly id: number;
    readonly name: string;
  }>;
  readonly " $fragmentType": "CreateHolidaysGroupForm_viewer";
};
export type CreateHolidaysGroupForm_viewer$key = {
  readonly " $data"?: CreateHolidaysGroupForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateHolidaysGroupForm_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateHolidaysGroupForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "name": "asc"
          }
        }
      ],
      "concreteType": "holidays_groups",
      "kind": "LinkedField",
      "name": "holidays_groups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country_code",
          "storageKey": null
        }
      ],
      "storageKey": "holidays_groups(order_by:{\"name\":\"asc\"})"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "9df797d58e95037505bd5766593dd328";

export default node;
