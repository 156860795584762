/**
 * @generated SignedSource<<41867a9d2d3ef10026172c5520621520>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureFlags_gql$data = {
  readonly billing: {
    readonly subscription: {
      readonly id: string;
      readonly plan: {
        readonly id: string;
        readonly itemPriceId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly features: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"FeatureAccountCheckbox_feature">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ServerFlagsProvider_gql">;
  readonly " $fragmentType": "FeatureFlags_gql";
};
export type FeatureFlags_gql$key = {
  readonly " $data"?: FeatureFlags_gql$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlags_gql">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureFlags_gql",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "features",
      "kind": "LinkedField",
      "name": "features",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeatureAccountCheckbox_feature"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "syncSubscription",
          "value": false
        }
      ],
      "concreteType": "Billing",
      "kind": "LinkedField",
      "name": "billing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingSubscriptionItem",
              "kind": "LinkedField",
              "name": "plan",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "itemPriceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "billing(syncSubscription:false)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ServerFlagsProvider_gql"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "8220a74419d0c41f0375dfac54a98ebe";

export default node;
