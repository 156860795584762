import { Icon } from "@blueprintjs/core"
import React, { useEffect } from "react"
import { ToastContainer, TypeOptions, cssTransition } from "react-toastify"
import { toast as newToast } from "react-toastify"
import { match } from "ts-pattern"

import styles from "./ToasterContainer.module.css"

import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"

type ActionHref = {
  href: string
  text?: string
  target?: string
  onClick?: never
}

type ActionOnClick = {
  onClick: () => void
  text?: string
  href?: never
  target?: never
}

type Action = ActionHref | ActionOnClick

type Options = {
  message: string
  description?: string
  type?: TypeOptions
  actions?: [Action] | [Action, Action]
}

const ActionButton = (action: Action) => {
  return (
    <>
      {action?.href && (
        <a href={action.href} target={action.target || null}>
          {action.text || "View"}
        </a>
      )}
      {action?.onClick && (
        <a onClick={action.onClick}>{action.text || "View"}</a>
      )}
    </>
  )
}

export const ToastContent = (props: Omit<Options, "type">) => {
  // react-toastify doesn't have an action or description options
  // so we've created our own below
  const { message, description, actions } = props

  return (
    <div className={styles.toastContent}>
      <div data-test="toast-message" className={styles.message}>
        {message}
      </div>
      {description && <div>{description}</div>}
      {actions && actions.map((a, i) => <span key={i}>{ActionButton(a)}</span>)}
    </div>
  )
}

export const showToast = (options: Options) => {
  const { type, actions } = options

  const sharedOptions = {
    autoClose: actions ? 8000 : 5000,
  }

  if (!type || type === "default") {
    return newToast(<ToastContent {...options} />, sharedOptions)
  }

  if (type) {
    const color = `var(--toastify-icon-color-${type})`
    const icon = match(type)
      .with("success", () => <Icon icon="tick-circle" color={color} />)
      .with("warning", () => <Icon icon="issue" color={color} />)
      .with("error", () => <Icon icon="issue" color={color} />)
      .with("info", () => <Icon icon="info-sign" color={color} />)
      .otherwise(() => undefined)

    return newToast[type](<ToastContent {...options} />, {
      ...sharedOptions,
      icon,
    })
  }
}

const handleToast = (e: CustomEvent<Options>) => {
  showToast(e.detail)
}

const slideRight = cssTransition({
  enter: styles.slideRightEnter,
  exit: styles.slideRightExit,
})

export const Toaster = () => {
  // Page load notifications
  useEffect(() => {
    if ("__RUNN_NOTIFY__" in window && Array.isArray(window.__RUNN_NOTIFY__)) {
      for (const event of window.__RUNN_NOTIFY__) {
        showToast(event)
      }
    }
  }, [])

  // Websocket based notifications
  useEffect(() => {
    document.body.addEventListener("runnNotification", handleToast)
    return () => {
      document.body.removeEventListener("runnNotification", handleToast)
    }
  }, [])

  return (
    <ToastContainer
      className={styles.toastContainer}
      position="bottom-right"
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      transition={slideRight}
    />
  )
}

export default withRelayPageContainerNoLoading(Toaster)
