import React, { useState } from "react"

import styles from "./DeleteModal.module.css"

import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramAlert } from "~/common/tangrams/tangramAlert"

// TODO(permissions): refactor delete logic to exclude admin checks
type Props = {
  type:
    | "project"
    | "person"
    | "client"
    | "rate card"
    | "team"
    | "role"
    | "user account"
    | "account"
    | "skill"
    | "holiday group"
    | "contract"
    | "placeholder"
    | "project template"
    | "integration"
    | "field"
    | "view"
    | "option"
    | "report"
  name: string
  isAdmin: boolean
  adminCanDelete?: boolean
  deletable: boolean
  closeDialog: () => void
  onSubmit: () => Promise<void> | void
  hint?: string | React.ReactElement
  customWarning?: string | React.ReactElement
  customConfirmation?: string | React.ReactElement
  customHeader?: string
}

const DeleteModal = (props: Props) => {
  const {
    type,
    name,
    isAdmin,
    adminCanDelete = false,
    deletable,
    closeDialog,
    onSubmit,
    hint,
    customWarning,
    customHeader,
    customConfirmation,
  } = props

  const [deleteValue, setDeleteValue] = useState("")
  const [isDeleting, setIsDeleting] = useState(false)

  const handleClose = () => {
    if (!isDeleting) {
      closeDialog()
      setDeleteValue("")
    }
  }

  const handleValue = (e) => {
    setDeleteValue(e.target.value.toUpperCase())
  }

  const handleSubmit = async () => {
    setIsDeleting(true)
    await onSubmit()
    setIsDeleting(false)
  }

  const tangramStyles = { bottom: 20 }

  if (isAdmin && adminCanDelete) {
    // some modals are passed adminCanDelete to let admins
    // force delete even if it may affect historic data
    return (
      <ModalFormWrapper
        headerTitle={
          customHeader ? (
            <>{customHeader}</>
          ) : (
            <p>
              Are you sure you want to delete <strong>{name}</strong>?
            </p>
          )
        }
        tangram={<TangramAlert />}
        tangramStyles={tangramStyles}
      >
        <ModalBody>
          <div className={styles.body}>
            {customWarning ? (
              <p style={{ marginBottom: 20 }}>{customWarning}</p>
            ) : (
              <p style={{ marginBottom: 20 }}>
                This {type} <b>has historic data</b>, such as past assignments
                or actuals. If you want to keep this data for reporting, we
                recommend you archive this {type} instead.
              </p>
            )}
            {customConfirmation ? (
              <p>{customConfirmation}</p>
            ) : (
              <p>
                If you are sure you want to delete <strong>{name}</strong> and
                all its data forever please type{" "}
                <b className={`notranslate ${styles.red}`}>DELETE</b> below.
              </p>
            )}
          </div>
          <Input
            name="delete"
            value={deleteValue}
            onChange={handleValue}
            className={styles.red}
            nofocus
            autoFocus
          />
        </ModalBody>
        <ModalFooter>
          <Button
            text="Cancel"
            disabled={isDeleting}
            onClick={handleClose}
            tabIndex={-1}
          />
          <Button
            text={`Delete ${type}`}
            intent="danger"
            loading={isDeleting}
            onClick={handleSubmit}
            outlined={false}
            disabled={deleteValue !== "DELETE" || isDeleting}
            style={{ textTransform: "capitalize" }}
          />
        </ModalFooter>
      </ModalFormWrapper>
    )
  }

  if (deletable) {
    return (
      <ModalFormWrapper
        headerTitle={
          customHeader ? (
            <>{customHeader}</>
          ) : (
            <p>
              Are you sure you want to delete <strong>{name}</strong>?
            </p>
          )
        }
        tangram={<TangramAlert />}
        tangramStyles={tangramStyles}
      >
        <ModalBody>
          {customWarning ? (
            <p>{customWarning}</p>
          ) : (
            <p>This action cannot be undone.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            text="Cancel"
            disabled={isDeleting}
            onClick={handleClose}
            tabIndex={-1}
          />
          <Button
            text={`Delete ${type}`}
            intent="danger"
            onClick={handleSubmit}
            disabled={isDeleting}
            outlined={false}
            style={{ textTransform: "capitalize" }}
            data-test={`delete-${type.replace(/ /g, "")}-button`}
          />
        </ModalFooter>
      </ModalFormWrapper>
    )
  }

  if (!deletable) {
    return (
      <ModalFormWrapper
        headerTitle={
          <p>
            You cannot delete <strong>{name}</strong>
          </p>
        }
        tangram={<TangramAlert />}
        tangramStyles={tangramStyles}
      >
        <ModalBody>
          {hint ? (
            <>{hint}</>
          ) : (
            <>
              <strong>{name}</strong> cannot be deleted.
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button text="OK" onClick={closeDialog} />
        </ModalFooter>
      </ModalFormWrapper>
    )
  }
}

export default DeleteModal
