/**
 * @generated SignedSource<<f8dacf53d90e395c61e70a239d24b35e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RateCardDeleteMutation$variables = {
  id: number;
};
export type RateCardDeleteMutation$data = {
  readonly deleteRateCard: {
    readonly account: {
      readonly id: number;
      readonly rate_cards: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type RateCardDeleteMutation = {
  response: RateCardDeleteMutation$data;
  variables: RateCardDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteRateCardResult",
    "kind": "LinkedField",
    "name": "deleteRateCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order_by",
                "value": {
                  "name": "asc"
                }
              }
            ],
            "concreteType": "rate_cards",
            "kind": "LinkedField",
            "name": "rate_cards",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": "rate_cards(order_by:{\"name\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardDeleteMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCardDeleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8dd817e7a1b98a4fc50bfb9700d4a3d1",
    "id": null,
    "metadata": {},
    "name": "RateCardDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation RateCardDeleteMutation(\n  $id: Int!\n) {\n  deleteRateCard(id: $id) {\n    account {\n      id\n      rate_cards(order_by: {name: asc}) {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d33a0365afc0517895432f2aef5ab20";

export default node;
