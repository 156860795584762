/**
 * @generated SignedSource<<1183cd77c645628ea09c251aabfaadff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkstreamsContainerQuery$variables = Record<PropertyKey, never>;
export type WorkstreamsContainerQuery$data = {
  readonly billing: {
    readonly " $fragmentSpreads": FragmentRefs<"EntitlementsProvider_billing">;
  } | null | undefined;
  readonly current_user: {
    readonly account: {
      readonly id: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user" | "WorkstreamCard_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ServerFlagsProvider_gql">;
};
export type WorkstreamsContainerQuery = {
  response: WorkstreamsContainerQuery$data;
  variables: WorkstreamsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "kind": "Literal",
    "name": "syncSubscription",
    "value": false
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkstreamsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkstreamCard_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntitlementsProvider_billing"
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ServerFlagsProvider_gql"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WorkstreamsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "workstreams",
                "kind": "LinkedField",
                "name": "workstreams",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "all_workstreams",
                "args": null,
                "concreteType": "workstreams",
                "kind": "LinkedField",
                "name": "workstreams",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people_managers",
            "kind": "LinkedField",
            "name": "manageable_people",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "linkedPerson",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInTrial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemPriceId",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscriptionEntitlement",
            "kind": "LinkedField",
            "name": "entitlements",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features_accounts",
        "kind": "LinkedField",
        "name": "features_accounts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feature_id",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a29e56dc822b631dd777b882f5594596",
    "id": null,
    "metadata": {},
    "name": "WorkstreamsContainerQuery",
    "operationKind": "query",
    "text": "query WorkstreamsContainerQuery {\n  current_user {\n    account {\n      id\n    }\n    ...WorkstreamCard_user\n    ...PermissionsProvider_user\n  }\n  billing(syncSubscription: false) {\n    ...EntitlementsProvider_billing\n  }\n  ...ServerFlagsProvider_gql\n}\n\nfragment EntitlementsProvider_billing on Billing {\n  subscription {\n    id\n    isInTrial\n    plan {\n      itemPriceId\n      id\n    }\n  }\n  entitlements {\n    featureId\n    featureType\n    value\n  }\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n  manageable_people {\n    id\n    person {\n      id\n    }\n  }\n  linkedPerson {\n    id\n  }\n}\n\nfragment ServerFlagsProvider_gql on query_root {\n  features {\n    id\n    enabled\n    description\n    name\n  }\n  features_accounts {\n    id\n    feature_id\n    enabled\n  }\n}\n\nfragment WorkstreamCard_user on users {\n  id\n  account {\n    id\n    workstreams {\n      id\n      name\n      archived\n    }\n  }\n  ...WorkstreamForm_user\n  ...WorkstreamItem_user\n}\n\nfragment WorkstreamForm_user on users {\n  account {\n    all_workstreams: workstreams {\n      id\n      name\n    }\n  }\n}\n\nfragment WorkstreamItem_user on users {\n  id\n  ...WorkstreamForm_user\n}\n"
  }
};
})();

(node as any).hash = "38c94e2ece5773c3714cd407ba8cde4f";

export default node;
