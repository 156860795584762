/**
 * @generated SignedSource<<1fc433101456f3db24d804daa1a642ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteAccountFormQuery$variables = Record<PropertyKey, never>;
export type DeleteAccountFormQuery$data = {
  readonly current_user: {
    readonly user_accounts: ReadonlyArray<{
      readonly account: {
        readonly account_type: string;
        readonly alternative_account_id: number | null | undefined;
        readonly id: number;
        readonly name: string;
      };
      readonly id: number;
    }>;
  } | null | undefined;
};
export type DeleteAccountFormQuery = {
  response: DeleteAccountFormQuery$data;
  variables: DeleteAccountFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_accounts",
        "kind": "LinkedField",
        "name": "user_accounts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "account_type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alternative_account_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAccountFormQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteAccountFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "531eaeef44864cbd097e0f1e9678ba7a",
    "id": null,
    "metadata": {},
    "name": "DeleteAccountFormQuery",
    "operationKind": "query",
    "text": "query DeleteAccountFormQuery {\n  current_user {\n    user_accounts {\n      id\n      account {\n        id\n        name\n        account_type\n        alternative_account_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "266a22ff037df8d9c83b8d4241075fd1";

export default node;
