import { useFeature } from "flagged"
import React, { useState } from "react"
import { match } from "ts-pattern"

import styles from "./UpgradePlanModal.module.css"

import { ModalFormWrapper, ModalHeader } from "~/common/ModalForm"
import ModalBody from "~/common/ModalForm/ModalBody"
import ModalFooter from "~/common/ModalForm/ModalFooter"
import Select from "~/common/Select"
import Button from "~/common/buttons/Button"
import { TangramTriangle } from "~/common/tangrams"

import { subscriptionUpdate } from "~/mutations/Subscription"

import {
  CHARGEBEE_PROFESSIONAL_TRIAL_PRICE_ITEM_ID,
  CHARGEBEE_STARTER_TRIAL_PRICE_ITEM_ID,
  ChargebeeFeatures,
  PROFESIONAL_PLAN_USD_MONTHLY_AMOUNT,
} from "~/Entitlements/plansAndFeatures"
import { useEntitlementQuantity } from "~/Entitlements/useEntitlements"
import { SUBSCRIPTIONS } from "~/GLOBALVARS"
import { showToast } from "~/containers/ToasterContainer"

type Props = {
  isAdmin: boolean
  closeDialog: () => void
  isInPanel?: boolean
  onSuccess?: () => void
  planId?:
    | typeof CHARGEBEE_STARTER_TRIAL_PRICE_ITEM_ID
    | typeof CHARGEBEE_PROFESSIONAL_TRIAL_PRICE_ITEM_ID
  intent:
    | "ResourceLimit"
    | "StartTrial"
    | "StartProfessionalTrial"
    | "SwitchToProfessionalTrial"
    | "UpgradeToProfessional"
}

const planOptions = [
  {
    label: "Starter Plan",
    value: CHARGEBEE_STARTER_TRIAL_PRICE_ITEM_ID,
  },
  {
    label: "Professional Plan",
    value: CHARGEBEE_PROFESSIONAL_TRIAL_PRICE_ITEM_ID,
  },
]

const startTrialText = (
  <p>
    <b>Start a 30 day free trial</b> to access unlimited people and unlock
    additonal features. No credit card needed.
  </p>
)
const viewPlansLink = (
  <p className={styles.viewLink}>
    <a href="https://www.runn.io/pricing" target="_blank" rel="noopener">
      View plans
    </a>
  </p>
)

const UpgradePlanModal = (props: Props) => {
  const { closeDialog, isAdmin, isInPanel, onSuccess, planId, intent } = props
  const [trialPlan, setTrialPlan] = useState(
    planId ? planOptions.find((p) => p.value === planId) : planOptions[1],
  )
  const [isUpgrading, setIsUpgrading] = useState(false)
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const peopleEntitlement = useEntitlementQuantity(ChargebeeFeatures.people)
  const peopleLimit = entitlementsEnabled
    ? peopleEntitlement
    : SUBSCRIPTIONS.free_plan_limit

  const showSelector = !planId

  const onClick = async () => {
    setIsUpgrading(true)
    const subscriptionPayload = {
      input: {
        item_price_id: entitlementsEnabled
          ? trialPlan.value
          : SUBSCRIPTIONS.pro_plan_id,
      },
    }

    await subscriptionUpdate(subscriptionPayload)

    setIsUpgrading(false)
    showToast({
      message: "Upgrade Successful. This page will now reload.",
      type: "success",
    })
    setTimeout(() => window.location.reload(), 500)
    onSuccess?.()
  }

  const { title, content, buttonText, showViewPlanLink } = match(intent)
    .with("ResourceLimit", () => ({
      title: "Start a free trial to add more people",
      content: (
        <>
          <p>
            You have reached the limit of{" "}
            {entitlementsEnabled ? peopleLimit : SUBSCRIPTIONS.free_plan_limit}{" "}
            active people on the free plan
          </p>
          {startTrialText}
        </>
      ),
      buttonText: "Start Trial",
      showViewPlanLink: true,
    }))
    .with("StartTrial", () => ({
      title: "Free trial",
      content: <>{startTrialText}</>,
      buttonText: "Start Trial",
      showViewPlanLink: true,
    }))
    .with("StartProfessionalTrial", () => ({
      title: "Start Professional Trial",
      content: <>{startTrialText}</>,
      buttonText: "Start Professional Trial",
      showViewPlanLink: true,
    }))
    .with("SwitchToProfessionalTrial", () => ({
      title: "Switch to Professional Trial",
      content: (
        <>
          <p>Access more features with Runn's Professional Plan</p>
          {viewPlansLink}
        </>
      ),
      buttonText: "Switch to Professional Trial",
      showViewPlanLink: false,
    }))
    .with("UpgradeToProfessional", () => ({
      title: "Upgrade to Professional",
      content: (
        <>
          <p>
            <b>Access more features with Runn's Professional Plan</b>
          </p>
          <p>
            Your monthly billing will update to $
            {PROFESIONAL_PLAN_USD_MONTHLY_AMOUNT} USD per active user in Runn.
          </p>
          {viewPlansLink}
        </>
      ),
      buttonText: "Upgrade to Professional",
      showViewPlanLink: false,
    }))
    .exhaustive()

  const Wrapper = ({ children }) => {
    if (isInPanel) {
      return (
        <>
          <ModalHeader text={title} onClose={closeDialog} />
          {children}
        </>
      )
    }

    return (
      <ModalFormWrapper
        data-test="start-trial-modal"
        headerTitle={title}
        tangram={<TangramTriangle />}
      >
        {children}
      </ModalFormWrapper>
    )
  }

  return (
    <Wrapper>
      <ModalBody>
        <>
          {content}
          {isAdmin && entitlementsEnabled && showSelector && (
            <div className={styles.select}>
              <Select
                dataTest="trial-plan-select"
                name="trial-plan"
                value={trialPlan}
                onChange={setTrialPlan}
                label="Select a plan"
                options={planOptions}
              />
            </div>
          )}
          {!isAdmin ? (
            <p>Ask your account admin to upgrade this account.</p>
          ) : null}
          {showViewPlanLink && viewPlansLink}
        </>
      </ModalBody>
      <ModalFooter>
        <Button text="Close" onClick={closeDialog} />
        {isAdmin && (
          <Button
            text={buttonText}
            loading={isUpgrading}
            onClick={onClick}
            outlined={false}
            disabled={isUpgrading}
            style={{ textTransform: "capitalize" }}
          />
        )}
      </ModalFooter>
    </Wrapper>
  )
}

export default UpgradePlanModal
