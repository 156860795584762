import uniqBy from "lodash-es/uniqBy"
import React from "react"
import Select, { Props as SelectProps } from "react-select"
import Creatable from "react-select/creatable"

import { randomId } from "~/helpers/general-helpers"
import { sortAlphaNumeric } from "~/helpers/sorting-helpers"

export type ReactSelectProps = {
  id?: string
  fontSize?: number
  height?: number
  minHeight?: number
  disableMenuPortalTarget?: boolean
  dataTest?: string
  maxLabelLength?: number
  label?: string | JSX.Element
  width?: string | number
  controlStyles?: Record<string, any>
  optionStyles?: Record<string, any>
  tabIndex?: number
  isCreatable?: boolean
  doNotSortOptions?: boolean
  createOptionPosition?: "first" | "last"
  onClick?: () => void
  onChange: (a: any, b?: any) => void
} & Omit<SelectProps, "onChange">

export type Option = {
  label: string
  value: string | number
  isDisabled?: boolean
  __isNew__?: boolean
}

export type GroupedOption = {
  label: string
  options: Option[]
}

export const DIVIDER = {
  value: "{DIVIDER}",
  label: "{DIVIDER}",
} as const

const isDivider = (opt: Option) => opt.value === DIVIDER.value

const runnThemeColors = {
  primary: "var(--runn-blue)",
  primary25: "var(--snow)",
  primary50: "var(--cloud)",
  neutral80: "var(--midnight)", // main text
  danger: "var(--alert)",
  dangerLight: "var(--alert-light)",
  neutral0: "#fff",
  neutral5: "var(--snow)", // disabled background
  neutral10: "var(--smoke)", // disabled border
  neutral20: "var(--smoke)",
  neutral30: "var(--smoke)",
  neutral40: "var(--slate)", // disabled selected text
  neutral50: "var(--shadow)", // placeholder text & disabled unselected text
  neutral60: "var(--shadow)", // chevron indicator: focused
}

const SelectOptions = (props: ReactSelectProps) => {
  const { styles: extraStyles, maxLabelLength, ...restProps } = props
  const customTheme = (theme) => ({
    ...theme,
    borderRadius: "var(--rounded-sm)",
    colors: {
      ...theme.colors,
      ...runnThemeColors,
    },
    spacing: {
      ...theme.spacing,
      controlHeight: props.height || 40,
    },
  })

  const customStyles = {
    option: (base, state) => {
      const custom = {
        ...base,
        fontSize: props.fontSize || 13,
        overflow: "hidden", // Make sure we don't get horizontal scrollbars
        ...props.optionStyles,
      }

      if (state.isSelected) {
        custom.color = "white !important"
      }

      if (isDivider(state.data)) {
        return {
          ...custom,
          backgroundColor: "var(--cloud)",
          height: 1,
          cursor: "default",
          padding: 0,
        }
      }
      return custom
    },
    control: (base, state) => ({
      ...base,
      borderWidth: 1,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      width: props.width || "auto",
      fontSize: props.fontSize || 13,
      boxShadow: "0",
      ...props.controlStyles,
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 var(--spacing-xs)",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 var(--spacing-xs)",
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 0,
    }),
    placeholder: (base) => ({
      ...base,
      maxWidth: "calc(100% - 8px)",
      overflow: "hidden",
      position: "absolute",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    groupHeading: (base, { id, data }) => ({
      ...base,
      // First group doesn't have a divider
      borderTop: id.includes("group-0-heading")
        ? "none"
        : "1px solid var(--cloud)",
      fontSize: props.fontSize || 13,
      fontWeight: 510,
      color: "var(--midnight)",
      letterSpacing: "1px",
      padding: "10px",
      display: data.label ? "block" : "none",
    }),
    group: (base) => ({
      ...base,
      padding: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: 0,
      backgroundColor: "var(--snow)",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: 13,
      letterSpacing: "-0.01em",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "var(--smoke)",
    }),
    ...extraStyles,
  }

  const onBlur = (event) => {
    const name = event.target.value.trim()
    if (name) {
      const newValue = { label: name, value: name }
      if (Array.isArray(props.value)) {
        // Dont allow duplicate values
        if (
          props.value.find(
            (v) => v.label.toLowerCase().trim() === name.toLowerCase().trim(),
          )
        ) {
          return props.onChange(props.value)
        }
        props.onChange([...props.value, newValue])
      } else if (props.isMulti) {
        props.onChange([newValue])
      } else {
        props.onChange(newValue)
      }
    }
  }

  const menuPortalTarget = props.menuPortalTarget
    ? props.menuPortalTarget
    : document.body

  const useMenuPortalTarget = !props.disableMenuPortalTarget && menuPortalTarget

  const getFilterableOptions = (options: Option[] | undefined) => {
    if (!options) {
      return null
    }

    const dividerIndexes = options.reduce(
      (indexes, opt, i) => (isDivider(opt) ? [...indexes, i] : indexes),
      [],
    )
    const selectableOptions = options.filter((opt) => !isDivider(opt))
    // This is a workaround for a change introduced in ver.5 of React-select (https://github.com/JedWatson/react-select/pull/4702)
    // where new options with __isNew__ property will always show and not filtered out
    const filtered = selectableOptions.map(({ __isNew__, ...rest }) => rest)
    const uniqueOptions = uniqBy(filtered, "value")

    // If dividers have been added, then sorting the options doesn't make sense
    const sortedOptions =
      props.doNotSortOptions || dividerIndexes.length
        ? uniqueOptions
        : uniqueOptions.sort((a, b) => sortAlphaNumeric(a.label, b.label))

    for (const dividerIndex of dividerIndexes) {
      sortedOptions.splice(dividerIndex, 0, { ...DIVIDER, label: "" })
    }

    return sortedOptions
  }

  const getOptions = (options) => {
    const isGroupedOption = options.some((o) => o.options)
    if (isGroupedOption) {
      return options
        .sort((a, b) => {
          if (a.pinned !== b.pinned) {
            return a.pinned ? -1 : 1 // put pinned groups at the top
          }
        })
        .map((o) => {
          return { ...o, options: getFilterableOptions(o.options) }
        })
    }

    // Normal options
    return getFilterableOptions(options)
  }

  // This takes care of issues where "Creatable" is used with "groupedOptions"
  // and option still exists: https://github.com/JedWatson/react-select/issues/3726
  // START //
  const isOptionMatchesInputValue = (option, inputValue: string) => {
    const isGroupedOption = Boolean(option.options)
    if (!isGroupedOption && option.label) {
      return (
        String(option.label).toLowerCase().trim() ===
        String(inputValue).toLowerCase().trim()
      )
    }
    if (isGroupedOption) {
      for (const opt of option.options) {
        if (isOptionMatchesInputValue(opt, inputValue)) {
          return true
        }
      }
    }
    return false
  }

  const isValidNewOption = (
    inputValue: string,
    selectValue: Option[],
    selectOptions: Option[] | GroupedOption[],
  ) => {
    if (!inputValue.trim()) {
      return false
    }
    if (
      typeof maxLabelLength === "number" &&
      inputValue.length > maxLabelLength
    ) {
      return false
    }

    let isValid = true

    for (const option of selectOptions) {
      if (isOptionMatchesInputValue(option, inputValue)) {
        isValid = false
        break
      }
    }
    for (const option of selectValue) {
      if (isOptionMatchesInputValue(option, inputValue)) {
        isValid = false
        break
      }
    }

    return isValid
  }
  // END //

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (
      typeof maxLabelLength === "number" &&
      inputValue?.trim().length > maxLabelLength
    ) {
      return "Please enter a shorter name."
    }
    if (restProps.noOptionsMessage) {
      return restProps.noOptionsMessage({ inputValue })
    }
    return "No options"
  }

  // Ensure that an id is always set for assistive technologies
  const id = props.id || `react-select-${randomId()}`
  const inputId = `${id}-input`

  return (
    <div
      data-test={props.dataTest}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "left",
      }}
      className="react-select"
    >
      {props.label && (
        <label
          htmlFor={inputId}
          style={{
            fontSize: 12,
            color: "var(--midnight)",
            fontWeight: 600,
            whiteSpace: "nowrap",
          }}
        >
          {props.label}
        </label>
      )}
      {props.isCreatable ? (
        <Creatable
          styles={customStyles}
          isClearable
          onChange={props.onChange}
          onBlur={onBlur}
          menuPortalTarget={useMenuPortalTarget}
          {...restProps}
          options={getOptions(props.options)}
          isValidNewOption={isValidNewOption}
          noOptionsMessage={noOptionsMessage}
          id={id}
          inputId={inputId}
          menuPlacement="auto"
          theme={customTheme}
        />
      ) : (
        <Select
          styles={customStyles}
          menuPortalTarget={useMenuPortalTarget}
          {...restProps}
          id={id}
          inputId={inputId}
          options={getOptions(props.options)}
          menuPlacement="auto"
          isOptionDisabled={(o: Option) => isDivider(o) || o.isDisabled}
          theme={customTheme}
        />
      )}
    </div>
  )
}

export default SelectOptions
