/**
 * @generated SignedSource<<9e97e8e9e47ba670c11674a0be5fb862>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PersonActivateMutation$variables = {
  id: number;
};
export type PersonActivateMutation$data = {
  readonly activatePerson: {
    readonly account: {
      readonly id: number;
      readonly people_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
    } | null | undefined;
    readonly person: {
      readonly active: boolean;
      readonly id: number;
    } | null | undefined;
  };
};
export type PersonActivateMutation = {
  response: PersonActivateMutation$data;
  variables: PersonActivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "PersonResult",
    "kind": "LinkedField",
    "name": "activatePerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  },
                  "is_placeholder": {
                    "_eq": false
                  }
                }
              }
            ],
            "concreteType": "people_aggregate",
            "kind": "LinkedField",
            "name": "people_aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "people_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "people_aggregate(where:{\"active\":{\"_eq\":true},\"is_placeholder\":{\"_eq\":false}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonActivateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonActivateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fad72c3ffd97b73d2951d2576802a467",
    "id": null,
    "metadata": {},
    "name": "PersonActivateMutation",
    "operationKind": "mutation",
    "text": "mutation PersonActivateMutation(\n  $id: Int!\n) {\n  activatePerson(id: $id) {\n    person {\n      id\n      active\n    }\n    account {\n      id\n      people_aggregate(where: {active: {_eq: true}, is_placeholder: {_eq: false}}) {\n        aggregate {\n          count\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "520ec28eef10e45a653cc04a7603d135";

export default node;
