/**
 * @generated SignedSource<<a6c3fd86f032cd22ef59524747c67560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServerFlagsProvider_gql$data = {
  readonly features: ReadonlyArray<{
    readonly description: string | null | undefined;
    readonly enabled: boolean;
    readonly id: string;
    readonly name: string;
  }>;
  readonly features_accounts: ReadonlyArray<{
    readonly enabled: boolean;
    readonly feature_id: string;
    readonly id: number;
  }>;
  readonly " $fragmentType": "ServerFlagsProvider_gql";
};
export type ServerFlagsProvider_gql$key = {
  readonly " $data"?: ServerFlagsProvider_gql$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServerFlagsProvider_gql">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServerFlagsProvider_gql",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "features",
      "kind": "LinkedField",
      "name": "features",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "features_accounts",
      "kind": "LinkedField",
      "name": "features_accounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "feature_id",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "e20255a758a3797c160fab44be323698";

export default node;
