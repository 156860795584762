import { ButtonProps } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./PlanButton.module.css"

import Button from "./Button"

type Props = {
  label?: string
  onClick?: ButtonProps["onClick"]
  rounded?: boolean
  href?: string
  style?: "solid" | "outline"
  className?: string
}

const PlanButton = ({
  onClick,
  label = "View all Plans",
  rounded = true,
  style = "solid",
  href = "https://www.runn.io/pricing",
  className,
}: Props) => {
  return (
    <Button
      className={cc([
        className,
        styles.button,
        styles[style],
        { [styles.rounded]: rounded },
      ])}
      href={href}
      onClick={onClick}
      target="_blank"
    >
      {label}
    </Button>
  )
}

export default PlanButton
