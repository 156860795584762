/**
 * @generated SignedSource<<3c7cefcad98022699fbec75ac733ba74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserModalQuery$variables = {
  skip: boolean;
  userId: number;
};
export type UserModalQuery$data = {
  readonly user?: {
    readonly id: number;
    readonly manageable_people: ReadonlyArray<{
      readonly id: number;
      readonly person_id: number;
    }>;
    readonly manageable_projects: ReadonlyArray<{
      readonly id: number;
      readonly project_id: number;
    }>;
  } | null | undefined;
};
export type UserModalQuery = {
  response: UserModalQuery$data;
  variables: UserModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": "user",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
          }
        ],
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "users_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people_managers",
            "kind": "LinkedField",
            "name": "manageable_people",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserModalQuery",
    "selections": (v3/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserModalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b300d3659a5264957d0e358a88321a43",
    "id": null,
    "metadata": {},
    "name": "UserModalQuery",
    "operationKind": "query",
    "text": "query UserModalQuery(\n  $userId: Int!\n  $skip: Boolean!\n) {\n  user: users_by_pk(id: $userId) @skip(if: $skip) {\n    id\n    manageable_people {\n      id\n      person_id\n    }\n    manageable_projects {\n      id\n      project_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e27d315f8405dc2ee032280b3801dfe";

export default node;
