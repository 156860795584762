/**
 * @generated SignedSource<<1bcfc531f4314300119b84d5ce018a86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountActions_account$data = {
  readonly account_type: string;
  readonly cancel_reasons: ReadonlyArray<{
    readonly account_name: string | null | undefined;
  }>;
  readonly id: number;
  readonly name: string;
  readonly subscription: any | null | undefined;
  readonly users: ReadonlyArray<{
    readonly permissions: any;
  }>;
  readonly " $fragmentType": "AccountActions_account";
};
export type AccountActions_account$key = {
  readonly " $data"?: AccountActions_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountActions_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountActions_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "account_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "users",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "cancel_reasons",
      "kind": "LinkedField",
      "name": "cancel_reasons",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "4eb8ba3e377bc046bd2d010c68f53d08";

export default node;
