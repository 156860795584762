import * as fe from "@runn/filter-engine"

import {
  PeopleGroupByOption,
  ProjectGroupByOption,
} from "./helpers/group-helpers"
import {
  PeopleSortOption,
  PlaceholderSortOption,
  ProjectSortOption,
} from "./helpers/sorting-helpers"
import {
  AllowedPeopleFilterSet,
  AllowedProjectFilterSet,
} from "~/helpers/filter-engine"

import { SortByProps } from "./common/TableWithFilter/TableWithFilter"

import { ReportPeriodType } from "~/reports/reportHelpers/shared"
import { ReportDisplayUnits } from "~/reports_v2/hooks/useReportDisplayUnits"

import { FeatureFlagSort } from "./DevTools/FeatureFlags/FeatureFlags"
import { ACCOUNT_ID } from "./GLOBALVARS"
import { SummaryUnit } from "./Planner/reducer2/peopleSummarySlice"
import {
  ProjectView,
  TemplateView,
} from "./ProjectDashboard/ProjectDashboardContainer"

export type Settings = {
  activePage?: "projects" | "people"
  calendarViewInMonths?: number
  expandWeekendsOnMonthView?: boolean
  expandedPeopleGroups?: {
    groupByFilter: PeopleGroupByOption
    expanded: string[]
  }[]
  expandedProjectGroups?: {
    groupByFilter: ProjectGroupByOption
    expanded: string[]
  }[]
  hideTrialModal?: boolean
  projectListOrder?: SortByProps
  roleListOrder?: SortByProps
  meFilter?: boolean
  peopleFilterSet?: AllowedPeopleFilterSet
  peopleFilterIncludeArchivedProjects?: boolean
  peoplePlannerGroupBy?: { value: PeopleGroupByOption; label: string }
  peoplePlannerSort?: {
    value: PeopleSortOption
    label: string
  }
  placeholderPlannerSort?: {
    value: PlaceholderSortOption
    label: string
  }
  peopleUtilizationGroup?: boolean
  peopleSummaryUnit?: SummaryUnit
  peopleSummaryEffortUnified?: boolean
  peopleSummaryWeekly?: boolean
  phaseColorLastUsed?: string
  plannerGraphHeight?: number
  plannerProjectsExpanded?: Array<{
    id: number
    groupName: string
  }>
  plannerPeopleExpanded?: Array<{
    id: number
    groupName: string
  }>
  projectDashboard_projectView?: ProjectView
  projectFilterSet?: AllowedProjectFilterSet
  projectPlannerGroupBy?: { value: ProjectGroupByOption; label: string }
  projectPlannerSort?: {
    value: ProjectSortOption
    label: string
  }
  projectVariance_periodView?: "day" | "week" | "month"
  reports?: Record<
    string,
    {
      period: ReportPeriodType
      fields: {
        name: string
        checked: boolean
      }[]
      metrics: {
        name: string
        checked: boolean
      }[]
      groupBy: string[]
      showDetailed: boolean
    }
  >
  projectWildSearch?: string
  peopleWildSearch?: string
  savedPeopleFilterSetList?: fe.engines.local.AllowedFilterSet[]
  savedProjectFilterSetList?: fe.engines.local.AllowedFilterSet[]
  showProjectPhases?: boolean
  timeOffBarCollapsed?: boolean
  templateDashboard_templateView?: TemplateView
  projectTemplateBarCollapsed?: boolean
  enableAllTentative?: boolean
  projectBudgetTabId?: "budget" | "expenses"
  plannerStatsType?: "hours" | "revenue" | "days"
  plannerStatsGroupBy?: "roles" | "workstreams"
  featureFlagSortBy?: FeatureFlagSort
  reportsVersion?: "v1" | "v2"
  reportsDisplayUnits?: ReportDisplayUnits
}

const ls = window.localStorage ? window.localStorage : false

const settingsKey = `runn_${ACCOUNT_ID}`

const write = (runnSettings: unknown) => {
  if (!ls) {
    return false
  }
  try {
    ls.setItem(settingsKey, JSON.stringify(runnSettings))
    return true
  } catch (e) {
    return false
  }
}

export const getSettings = (): Settings => {
  if (!ls) {
    return {}
  }

  const localStorageData = ls.getItem(settingsKey)
  const runnSetting = JSON.parse(localStorageData) as Settings

  if (!runnSetting) {
    return {}
  }

  // Migration of old settings. Can be removed after 2024-10-01
  // Only allow these 3 values for plannerStatsType
  if (!["hours", "revenue", "days"].includes(runnSetting.plannerStatsType)) {
    runnSetting.plannerStatsType = "hours"
  }

  return runnSetting
}

// overwrite a setting by passing in the key/value pair
export const setSetting = <K extends keyof Settings>(
  key: K,
  value: Settings[K],
) => {
  const runnSetting = getSettings()
  runnSetting[key] = value
  write(runnSetting)
}

// update a setting by using a function that receives the current value and
// returns the updated value
export const updateSetting = <K extends keyof Settings>(
  key: K,
  mapFn: (input: Settings[K]) => Settings[K],
) => {
  const runnSetting = getSettings()
  runnSetting[key] = mapFn(runnSetting[key])
  write(runnSetting)
}

// delete a setting
export const removeSetting = <K extends keyof Settings>(key: K) => {
  const runnSetting = getSettings()
  delete runnSetting[key]
  return write(runnSetting)
}

export default {
  getSettings,
  setSetting,
  updateSetting,
  removeSetting,
}
