/**
 * @generated SignedSource<<cf88d092673a0c8b9492043a4c7a6cea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PeoplePanelQuery$variables = Record<PropertyKey, never>;
export type PeoplePanelQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly email: string | null | undefined;
        readonly first_name: string;
        readonly id: number;
        readonly last_name: string;
      }>;
    };
    readonly id: number;
  } | null | undefined;
};
export type PeoplePanelQuery = {
  response: PeoplePanelQuery$data;
  variables: PeoplePanelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "users",
    "kind": "LinkedField",
    "name": "current_user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  },
                  "is_placeholder": {
                    "_eq": false
                  }
                }
              }
            ],
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": "people(where:{\"active\":{\"_eq\":true},\"is_placeholder\":{\"_eq\":false}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PeoplePanelQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PeoplePanelQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b929bf7ba221ad0308a5b682ab5c8745",
    "id": null,
    "metadata": {},
    "name": "PeoplePanelQuery",
    "operationKind": "query",
    "text": "query PeoplePanelQuery {\n  current_user {\n    id\n    account {\n      id\n      people(where: {active: {_eq: true}, is_placeholder: {_eq: false}}) {\n        id\n        first_name\n        last_name\n        email\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f84a463d3980b33f6eea78e38a9af59b";

export default node;
