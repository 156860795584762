/**
 * @generated SignedSource<<ed158ef1f65a8336e2b835c18e082289>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectRescheduleMutation$variables = {
  date: any;
  id: number;
};
export type ProjectRescheduleMutation$data = {
  readonly rescheduleProject: {
    readonly project: {
      readonly assignments: ReadonlyArray<{
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly is_billable: boolean | null | undefined;
        readonly minutes_per_day: number;
        readonly non_working_day: boolean;
        readonly note: string | null | undefined;
        readonly person_id: number;
        readonly phase_id: number | null | undefined;
        readonly project_id: number;
        readonly role_id: number;
        readonly start_date: string | null | undefined;
        readonly total_minutes: number | null | undefined;
        readonly updated_at: string;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly calc_end_date: string | null | undefined;
      readonly calc_start_date: string | null | undefined;
      readonly id: number;
      readonly milestones: ReadonlyArray<{
        readonly date: string | null | undefined;
        readonly icon: string | null | undefined;
        readonly id: number;
        readonly note: string | null | undefined;
        readonly project_id: number;
      }>;
      readonly name: string;
      readonly phases: ReadonlyArray<{
        readonly color: string;
        readonly end_date: string | null | undefined;
        readonly id: number;
        readonly name: string;
        readonly project_id: number;
        readonly start_date: string | null | undefined;
      }>;
    } | null | undefined;
  };
};
export type ProjectRescheduleMutation = {
  response: ProjectRescheduleMutation$data;
  variables: ProjectRescheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v5 = {
  "alias": "start_date",
  "args": null,
  "kind": "ScalarField",
  "name": "start_date_runn",
  "storageKey": null
},
v6 = {
  "alias": "end_date",
  "args": null,
  "kind": "ScalarField",
  "name": "end_date_runn",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ProjectResult",
    "kind": "LinkedField",
    "name": "rescheduleProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calc_start_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calc_end_date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "assignments",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phase_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workstream_id",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minutes_per_day",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_billable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total_minutes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "non_working_day",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "milestones",
            "kind": "LinkedField",
            "name": "milestones",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": "date",
                "args": null,
                "kind": "ScalarField",
                "name": "date_runn",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "icon",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "phases",
            "kind": "LinkedField",
            "name": "phases",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectRescheduleMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectRescheduleMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "3b2926842920dbcc6450c9099523d5c2",
    "id": null,
    "metadata": {},
    "name": "ProjectRescheduleMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectRescheduleMutation(\n  $date: Date!\n  $id: Int!\n) {\n  rescheduleProject(date: $date, id: $id) {\n    project {\n      id\n      name\n      calc_start_date\n      calc_end_date\n      assignments {\n        id\n        person_id\n        project_id\n        role_id\n        phase_id\n        workstream_id\n        note\n        start_date: start_date_runn\n        end_date: end_date_runn\n        minutes_per_day\n        is_billable\n        total_minutes\n        non_working_day\n        updated_at\n      }\n      milestones {\n        id\n        date: date_runn\n        project_id\n        note\n        icon\n      }\n      phases {\n        id\n        name\n        start_date: start_date_runn\n        end_date: end_date_runn\n        color\n        project_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "431ffbe6de3cf9c7b52e7aa234ea03b9";

export default node;
