/**
 * @generated SignedSource<<3d023dd67fcc4ce6cded2abeaea17d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InvitationRenewInput = {
  invitation_id: number;
};
export type InvitationRenewMutation$variables = {
  input: InvitationRenewInput;
};
export type InvitationRenewMutation$data = {
  readonly action_invitation_renew: {
    readonly invitation: {
      readonly expires_at: any;
      readonly id: number;
    } | null | undefined;
  };
};
export type InvitationRenewMutation = {
  response: InvitationRenewMutation$data;
  variables: InvitationRenewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InvitationRenewOutput",
    "kind": "LinkedField",
    "name": "action_invitation_renew",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "invitations",
        "kind": "LinkedField",
        "name": "invitation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expires_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationRenewMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationRenewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "108fdad652033e24afcf33badb7cbda2",
    "id": null,
    "metadata": {},
    "name": "InvitationRenewMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationRenewMutation(\n  $input: InvitationRenewInput!\n) {\n  action_invitation_renew(input: $input) {\n    invitation {\n      id\n      expires_at\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c386cd7ef1e42acd028149ea22bda33";

export default node;
