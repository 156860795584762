/**
 * @generated SignedSource<<c330a7361d51e5c6cb7c9164084c8b9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Financial = "all" | "none" | "restricted" | "%future added value";
export type PeopleManager = "all" | "none" | "restricted" | "%future added value";
export type ProjectManager = "all" | "none" | "restricted" | "%future added value";
export type UserType = "admin" | "contributor" | "manager" | "%future added value";
export type UpdateUserInput = {
  firstName: string;
  imageDataURI?: string | null | undefined;
  lastName: string;
  manageablePeople: ReadonlyArray<number>;
  manageableProjects: ReadonlyArray<number>;
  permissions: Permissions;
  userId: number;
};
export type Permissions = {
  add_all_people_to_projects: boolean;
  financial: Financial;
  manage_account: boolean;
  manage_people: PeopleManager;
  manage_projects: ProjectManager;
  type: UserType;
  view_planner: boolean;
};
export type UserPermissionsUpdateMutation$variables = {
  input: UpdateUserInput;
};
export type UserPermissionsUpdateMutation$data = {
  readonly updateUser: {
    readonly account: {
      readonly id: number;
    } | null | undefined;
    readonly user: {
      readonly email: string;
      readonly first_name: string | null | undefined;
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly last_name: string | null | undefined;
      readonly manageable_people: ReadonlyArray<{
        readonly id: number;
        readonly person_id: number;
      }>;
      readonly manageable_projects: ReadonlyArray<{
        readonly id: number;
        readonly project_id: number;
      }>;
      readonly permissions: any;
    } | null | undefined;
  };
};
export type UserPermissionsUpdateMutation = {
  response: UserPermissionsUpdateMutation$data;
  variables: UserPermissionsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserResult",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image_key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people_managers",
            "kind": "LinkedField",
            "name": "manageable_people",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserPermissionsUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserPermissionsUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "78a3e9c5f466ef81b030ae180a715b23",
    "id": null,
    "metadata": {},
    "name": "UserPermissionsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UserPermissionsUpdateMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    account {\n      id\n    }\n    user {\n      id\n      first_name\n      last_name\n      email\n      permissions\n      image_key\n      manageable_people {\n        id\n        person_id\n      }\n      manageable_projects {\n        id\n        project_id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff59e7682cf1639ff0bd7af964cfaebb";

export default node;
