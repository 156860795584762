import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"
import AutosizeInput from "react-input-autosize"

import styles from "./Tag.module.css"

import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"

export type Tag = {
  id: number
  name: string
}

type Props = {
  children: string | typeof AutosizeInput
  tag?: Tag
  canEdit?: boolean
  light?: boolean
  className?: string
  onDelete?: (tag: Tag) => void
}

const Tag = ({ canEdit, tag, light, children, className, onDelete }: Props) => {
  return (
    <div className={cc([styles.inlineFlex, className])}>
      <Dropdown
        Target={() => (
          <div
            data-component="Tag/Target"
            data-test={`tag-${tag?.name ?? "create-new"}`}
            className={cc({
              [styles.tag]: true,
              [styles.light]: light,
              [styles.editable]: canEdit,
            })}
          >
            <div className={styles.tagContent}>{children}</div>
            {canEdit && (
              <IconThreeDot className={styles.menuTarget} size={10} />
            )}
          </div>
        )}
        disabled={!canEdit}
      >
        <MenuItem
          text="Delete"
          icon={<Icon icon="trash" />}
          onClick={() => onDelete(tag)}
        />
      </Dropdown>
    </div>
  )
}

export default Tag
