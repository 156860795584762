/**
 * @generated SignedSource<<6532cda5da9f873f252c49bd59cf03b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureAccountCheckbox_feature$data = {
  readonly description: string | null | undefined;
  readonly enabled: boolean;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "FeatureAccountCheckbox_feature";
};
export type FeatureAccountCheckbox_feature$key = {
  readonly " $data"?: FeatureAccountCheckbox_feature$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureAccountCheckbox_feature">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureAccountCheckbox_feature",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "features",
  "abstractKey": null
};

(node as any).hash = "c4e12028b13ee210e501017aaaf576f0";

export default node;
