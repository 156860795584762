import { Icon, MenuDivider } from "@blueprintjs/core"
import { useFeature } from "flagged"
import { capitalize } from "lodash-es"
import React, { Suspense, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./AccountDropdownMenu.module.css"

import {
  AccountDropdownMenu_account$data,
  AccountDropdownMenu_account$key,
} from "./__generated__/AccountDropdownMenu_account.graphql"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import * as hashids from "~/helpers/hashids"
import { UserType } from "~/helpers/permissions"
import {
  editUserUrl,
  switchAccount,
  timesheetUrl,
  toggleTestAccount,
  userSignOut,
} from "~/helpers/routes"

import Dialog from "~/common/Dialog"
import { Dropdown } from "~/common/Dropdown"
import Loading from "~/common/Loading"
import MenuItem from "~/common/MenuItem"
import PaidFeatureMenuItemContainer from "~/common/PaidFeatureMenuItemContainer"
import { AddCircle, SignOut, Social } from "~/common/react-icons"

import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  useEntitlementSwitch,
  useIsInFreePlan,
  useIsInTrial,
} from "~/Entitlements/useEntitlements"
import { usePermissionsContext } from "~/Permissions/PermissionsProvider"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"
import UserModal from "~/Users/UserModal"
import { Mode } from "~/Users/userForm"
import NewAccountForm from "~/forms/AccountForms/NewAccountForm"
import InviteUserForm from "~/forms/UserForms/InviteUserForm"

import AccountDetails from "./AccountDetails"

type Props = { account }

const AccountDropdownMenu = (props: Props) => {
  const data = useFragment<AccountDropdownMenu_account$key>(
    graphql`
      fragment AccountDropdownMenu_account on query_root
      @argumentDefinitions(accountId: { type: "Int!" }) {
        account: accounts_by_pk(id: $accountId) {
          id
          name
          account_type
          timesheets_enabled
          alternative_account {
            account_type
          }
          currency
          default_full_time_minutes
        }
        current_user {
          id
          first_name
          last_name
          email
          image_key
          permissions
          ...PermissionsProvider_user

          user_accounts {
            id
            account {
              id
              name
              account_type
              alternative_account_id
            }
          }
        }
        ...ServerFlagsProvider_gql
      }
    `,
    props.account,
  )

  const { account, current_user } = data
  const {
    alternative_account: { account_type: alternativeAccountType },
  } = account

  const { can, subject, isContributor } = usePermissionsContext({
    user: current_user,
  })

  const permissionsUIEnabled = useFeature("permissions_ui")

  const entitlementsEnabled = useFeature("subscription_entitlements")
  const entitledToTimesheets = useEntitlementSwitch(
    ChargebeeFeatures.timesheets,
  )
  const isInTrial = useIsInTrial()
  const isInFreePlan = useIsInFreePlan()

  // Accounts that the user can switch to (ie: excluding the current account)
  const otherAccounts = current_user.user_accounts.filter(
    ({ account: otherAccount }) =>
      otherAccount.id !== account.id &&
      otherAccount.account_type !== "test" &&
      otherAccount.alternative_account_id !== account.id,
  )

  const [showAccountDialog, setShowAccountModal] = useState(false)
  const [showInviteDialog, setInviteDialogOpen] = useState(false)

  const canCreateInvitation = can("create", subject("Invitation"))
  const canCreateAccount = can("create", subject("Account"))
  const isSuperuser = current_user.permissions.type === UserType.Superuser

  const handleSwitchAccount = async (
    otherAccount: AccountDropdownMenu_account$data["current_user"]["user_accounts"][number]["account"],
  ) => {
    const response = await fetchWithCsrf(switchAccount(), {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        account_id: hashids.accounts.encode(otherAccount.id),
      }),
    })
    if (response.ok) {
      window.location.reload()
    }
  }

  /** This takes care of the fact that the timesheet menu can appear in the same
   * place depending on whether the accont is using the new entitlements and whether
   * it is in a trial
   */
  const showTimesheetsLinkTop = entitlementsEnabled
    ? account.timesheets_enabled && entitledToTimesheets && !isInTrial
    : account.timesheets_enabled && entitledToTimesheets

  return (
    <ServerFlagsProvider gql={data}>
      <Dropdown<typeof AccountDetails>
        className={styles.dropdown}
        disabled={showAccountDialog || showInviteDialog}
        Target={AccountDetails}
        targetProps={data}
        dataTest="account-dropdown"
        interactionKind={"hover"}
        hideArrow={true}
        minWidth={200}
      >
        <MenuDivider title={account.name} className={styles.divider} />
        <>
          {showTimesheetsLinkTop && (
            <MenuItem
              key="timesheets"
              data-test="account-dropdown-menu-item"
              text="My Timesheets"
              icon={<Icon icon="time" />}
              className={styles.item}
              href={timesheetUrl()}
            />
          )}

          <MenuItem
            key="settings"
            text="Settings"
            data-test="account-dropdown-menu-item"
            icon={<Icon icon="cog" color="var(--slate)" />}
            className={styles.item}
            href={editUserUrl(hashids.users.encode(current_user.id))}
          />
          {canCreateInvitation && (
            <MenuItem
              key="invite-users"
              data-test="account-dropdown-menu-item"
              text="Invite Users"
              icon={<Social />}
              className={styles.item}
              onClick={() => setInviteDialogOpen(true)}
            />
          )}
          {!isContributor && (
            <MenuItem
              key="switch-test-account"
              data-test="account-dropdown-menu-item"
              text={`Switch to ${capitalize(alternativeAccountType)} Account`}
              icon={<Icon icon="undo" color="var(--slate)" />}
              href={toggleTestAccount()}
              className={styles.item}
            />
          )}
          {!isSuperuser && !!otherAccounts.length && (
            <>
              <MenuDivider title="Other Accounts" className={styles.divider} />
              {otherAccounts.map(({ account: otherAccount }) => (
                <MenuItem
                  key={`switch-account-${otherAccount.name}`}
                  data-test="account-dropdown-menu-item"
                  text={otherAccount.name}
                  icon={<Icon icon="log-in" />}
                  onClick={() => handleSwitchAccount(otherAccount)}
                  className={styles.item}
                />
              ))}
            </>
          )}
          {!isSuperuser && canCreateAccount && (
            <MenuItem
              key="create-account"
              id="create-account"
              data-test="account-dropdown-menu-item"
              text="Create New Account"
              icon={<AddCircle />}
              className={styles.item}
              onClick={() => setShowAccountModal(true)}
            />
          )}
          {!showTimesheetsLinkTop && entitlementsEnabled && (
            <PaidFeatureMenuItemContainer roundedButton>
              <MenuItem
                key="timesheets"
                data-test="account-dropdown-menu-item"
                text="My Timesheets"
                icon={<Icon icon="time" />}
                className={styles.item}
                href={timesheetUrl()}
                softDisabled={isInFreePlan}
              />
            </PaidFeatureMenuItemContainer>
          )}
          <MenuDivider className={styles.divider} />
          <MenuItem
            key="sign-out"
            data-test="account-dropdown-menu-item"
            text="Sign out"
            icon={<SignOut />}
            className={styles.item}
            onClick={async () => {
              const response = await fetchWithCsrf(userSignOut(), {
                method: "DELETE",
              })
              if (response.ok) {
                window.location.reload()
              }
            }}
          />
        </>
      </Dropdown>
      <Dialog isOpen={showInviteDialog} enforceFocus>
        {permissionsUIEnabled ? (
          <Suspense fallback={<Loading delayMs={50} embed />}>
            <UserModal
              mode={Mode.INVITE}
              onClose={() => setInviteDialogOpen(false)}
            />
          </Suspense>
        ) : (
          <InviteUserForm closeDialog={() => setInviteDialogOpen(false)} />
        )}
      </Dialog>
      <Dialog isOpen={showAccountDialog} enforceFocus>
        <NewAccountForm
          userAccountNames={current_user.user_accounts.map(
            ({ account: userAccount }) => userAccount.name,
          )}
          defaultFullTimeMinutes={account.default_full_time_minutes}
          currency={account.currency}
          onClose={() => setShowAccountModal(false)}
        />
      </Dialog>
    </ServerFlagsProvider>
  )
}

export default withRelayPageContainerNoLoading(AccountDropdownMenu)
