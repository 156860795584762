/**
 * This store exists to make sure we periodically refetch the subscription details
 * if it doesn't exist yet. The reason it's in its own file is because it's
 * needed by a number of different components that do not share the same query root.
 * By extracing this to it's own store we can manage the query lifecycle and refetch
 * logic, making sure that each provider can use the store without having to manage
 * whether any other provider has already requested the data to be fetched. Without
 * something like this then we risk having multiple providers all trying to fetch
 * the data at the same time and then not sharing the responsse.
 */
import { useSyncExternalStore } from "react"
import { fetchQuery } from "react-relay"
import { graphql } from "react-relay"

import {
  EntitlementsStore_refetchQuery,
  EntitlementsStore_refetchQuery$data,
} from "./__generated__/EntitlementsStore_refetchQuery.graphql"

import { environment } from "~/store/hasura"

const REFETCH_QUERY = graphql`
  query EntitlementsStore_refetchQuery {
    billing(syncSubscription: false) {
      subscription {
        id
        isInTrial
        plan {
          itemPriceId
        }
      }
      entitlements {
        featureId
        featureType
        value
      }
    }
  }
`

let entitlementStoreInstance = null // Singleton reference

function createEntitlementStore(initialData) {
  let data = initialData || null
  const listeners = new Set()
  let inFlightRequest = null

  const notify = () => {
    listeners.forEach((listener: any) => listener())
  }

  const fetchFn = async () => {
    return fetchQuery<EntitlementsStore_refetchQuery>(
      environment,
      REFETCH_QUERY,
      {},
    )
      .toPromise()
      .then((queryData) => {
        return queryData.billing
      })
  }

  const fetchRetry = async () => {
    const maxRetries = 5
    const retryDelay = 5000
    let attempt = 0

    while (attempt < maxRetries) {
      try {
        const result = await fetchFn()

        if (result?.subscription) {
          // We have a subscription so can return the data
          return result
        }
      } catch (error) {
        console.warn(
          `Subscription entitlements refetch attempt ${attempt + 1} failed`,
        )
      }

      attempt += 1
      if (attempt < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay))
      }
    }

    // If we've retried 5 times and still don't have a subscription, return null
    return null
  }

  const fetchData = () => {
    if (inFlightRequest) {
      return inFlightRequest
    }

    inFlightRequest = fetchRetry()
      .then((result) => {
        data = result // Update store with the fetched data
        notify() // Notify subscribers about the new state
      })
      .finally(() => {
        inFlightRequest = null
      })

    return inFlightRequest
  }

  const subscribe = (listener) => {
    listeners.add(listener)
    return () => listeners.delete(listener)
  }

  const getSnapshot = () => data

  return {
    useStore: () => {
      return useSyncExternalStore(subscribe, getSnapshot, getSnapshot)
    },
    fetchData,
  }
}

/**
 * Singleton factory to create/retrieve the billing entitlements
 */
export function getEntitlementStore(
  initialData: EntitlementsStore_refetchQuery$data | null,
) {
  if (!entitlementStoreInstance) {
    // Create the store for the first time and set initial data if provided
    entitlementStoreInstance = createEntitlementStore(initialData)
  }

  return entitlementStoreInstance
}
