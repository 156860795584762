import React, { useState } from "react"
import { SubmitHandler, UseFormHandleSubmit } from "react-hook-form"

import styles from "./UserModal.module.css"

import { reportError } from "~/helpers/error-helpers"

import { ModalFooter } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { Launch } from "~/common/react-icons"

import { invitationCreateRelay } from "~/mutations/Invitation"
import { userPermissionsUpdateRelay } from "~/mutations/User"

import { showToast } from "~/containers/ToasterContainer"

import { Mode, User, UserForm, sanitizeFormData } from "./userForm"

const EDIT_USER_LINK = "https://help.runn.io/en/articles/9743063-user-types"

type Props = {
  onClose: () => void
  handleSubmit: UseFormHandleSubmit<UserForm>
  availableTabs: string[]
  selectedTabId: string
  navigateToPreviousTab: () => void
  navigateToNextTab: () => void
} & (
  | {
      mode: Mode.INVITE
    }
  | {
      mode: Mode.EDIT
      user: User
    }
)

const UserModalFooter = (props: Props) => {
  const {
    mode,
    onClose,
    handleSubmit,
    availableTabs,
    selectedTabId,
    navigateToPreviousTab,
    navigateToNextTab,
  } = props

  const [isLoading, setLoading] = useState(false)

  const onSubmit: SubmitHandler<UserForm> = async (values: UserForm) => {
    const sanitizedInput = {
      ...sanitizeFormData(values),
    }

    setLoading(true)

    if (mode === Mode.EDIT) {
      try {
        await userPermissionsUpdateRelay({
          input: { ...sanitizedInput, userId: props.user.id },
        })
      } catch (error) {
        void reportError(`Error editing user`, error)
      }
    }

    if (mode === Mode.INVITE) {
      // Map sanitized input to the hasura endpoint structure
      const invitationInput = {
        add_all_people_to_projects_permission:
          sanitizedInput.permissions.add_all_people_to_projects,
        email: values.email,
        fin_permission: sanitizedInput.permissions.financial,
        manage_account_permission: sanitizedInput.permissions.manage_account,
        manage_people_permission: sanitizedInput.permissions.manage_people,
        manage_projects_permission: sanitizedInput.permissions.manage_projects,
        user_type: sanitizedInput.permissions.type,
        view_planner_permission: sanitizedInput.permissions.view_planner,
        manage_project_ids: sanitizedInput.manageableProjects,
        manage_people_ids: sanitizedInput.manageablePeople,
      }
      const response = await invitationCreateRelay({ input: invitationInput })

      if (response.error) {
        showToast({ type: "error", message: response.error })
        setLoading(false)
        return
      } else {
        showToast({
          message: `${values.email} has been invited`,
          type: "success",
        })
      }
    }

    setLoading(false)

    onClose()
  }

  return (
    <ModalFooter>
      <div className={styles.footerText}>
        <a href={EDIT_USER_LINK} target="_blank" rel="noopener">
          User Types and Permission access
        </a>
        <Launch className={styles.launchIcon} />
      </div>
      <Button text="Cancel" onClick={onClose} intent={"danger"} />
      {mode === Mode.EDIT && (
        <>
          <Button
            text="Save"
            data-test="save-button"
            type="submit"
            outlined={false}
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          />
        </>
      )}
      {mode === Mode.INVITE && (
        <>
          {availableTabs.indexOf(selectedTabId) > 0 && (
            <Button text="Back" onClick={navigateToPreviousTab} />
          )}
          {availableTabs.indexOf(selectedTabId) < availableTabs.length - 1 ? (
            <Button
              text="Continue"
              data-test="continue-button"
              outlined={false}
              onClick={navigateToNextTab}
            />
          ) : (
            <Button
              text="Add User"
              data-test="add-user-button"
              type="submit"
              outlined={false}
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
            />
          )}
        </>
      )}
    </ModalFooter>
  )
}

export default UserModalFooter
