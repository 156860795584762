/**
 * @generated SignedSource<<1957053b81fb96c1d5297d9f54cadbe0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectLockTimeSheetMutation$variables = {
  id: number;
  timesheetLockedTo: any;
};
export type ProjectLockTimeSheetMutation$data = {
  readonly lockProjectTimeSheet: {
    readonly project: {
      readonly id: number;
      readonly timesheet_lock_status: any | null | undefined;
      readonly timesheet_lock_status_updated_at: string | null | undefined;
      readonly timesheet_locked_to: any | null | undefined;
    } | null | undefined;
  };
};
export type ProjectLockTimeSheetMutation = {
  response: ProjectLockTimeSheetMutation$data;
  variables: ProjectLockTimeSheetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timesheetLockedTo"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "timesheetLockedTo",
        "variableName": "timesheetLockedTo"
      }
    ],
    "concreteType": "ProjectResult",
    "kind": "LinkedField",
    "name": "lockProjectTimeSheet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timesheet_lock_status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timesheet_locked_to",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timesheet_lock_status_updated_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectLockTimeSheetMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectLockTimeSheetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "899d89a8186d9ea69826497fc3f323c8",
    "id": null,
    "metadata": {},
    "name": "ProjectLockTimeSheetMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectLockTimeSheetMutation(\n  $id: Int!\n  $timesheetLockedTo: Date!\n) {\n  lockProjectTimeSheet(id: $id, timesheetLockedTo: $timesheetLockedTo) {\n    project {\n      id\n      timesheet_lock_status\n      timesheet_locked_to\n      timesheet_lock_status_updated_at\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd09258a5d132ea73ee8ebd45c2bda4c";

export default node;
