import React, { useMemo, useState } from "react"
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./Panel.module.css"

import { PeoplePanelQuery } from "./__generated__/PeoplePanelQuery.graphql"

import { pluralize } from "~/helpers/plural"

import Select from "~/common/Select"
import Callout from "~/common/Text/Callout"
import Support from "~/common/Text/SupportingText"

import { UserForm, splitByComma } from "../userForm"

const PEOPLE_ACCESS_OPTIONS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Restricted",
    value: "restricted",
  },
]

const QUERY = graphql`
  query PeoplePanelQuery {
    current_user {
      id
      account {
        id
        people(
          where: { active: { _eq: true }, is_placeholder: { _eq: false } }
        ) {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`

const PeopleAccessLabel = <div className={styles.label}>People Access</div>
const ManagedPeopleLabel = <div className={styles.label}>Managed People</div>

type Props = {
  control: Control<UserForm>
  getValues: UseFormGetValues<UserForm>
  setValue: UseFormSetValue<UserForm>
  setError: UseFormSetError<UserForm>
  clearErrors: UseFormClearErrors<UserForm>
}

const PeoplePanel = ({
  control,
  getValues,
  setValue,
  setError,
  clearErrors,
}: Props) => {
  const data = useLazyLoadQuery<PeoplePanelQuery>(QUERY, {})
  const { people } = data.current_user.account

  // Control the select input value & select menu dropdown state
  const [inputValue, setInputValue] = useState("")
  const [open, setOpen] = useState(false)

  const managePeoplePermission = getValues("permissions.manage_people")

  const peopleOptions = useMemo(
    () =>
      (people ?? []).map((person) => ({
        value: person.id,
        label: `${person.first_name} ${person.last_name}`,
      })),
    [people],
  )

  // Convert a comma separated email address list into people on pressing enter
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Enter") {
      return
    }

    const inputContent = (e.target as HTMLTextAreaElement).value.trim()
    if (!inputContent) {
      return
    }

    const possibleEmails = splitByComma(inputContent)

    // Finding matching people
    const { matchingPeople, nonMatchingEmails } = possibleEmails.reduce(
      (acc, email) => {
        const matchingPerson = people.find((person) => person.email === email)
        if (matchingPerson) {
          acc.matchingPeople.push(matchingPerson)
        } else {
          acc.nonMatchingEmails.push(email)
        }
        return acc
      },
      { matchingPeople: [], nonMatchingEmails: [] },
    )

    setInputValue("") // Clear input value
    setOpen(false) // Collapse the dropdown

    if (matchingPeople.length) {
      const newManageablePeople = [
        ...getValues("manageablePeople"),
        ...matchingPeople.map(({ id }) => id),
      ]
      setValue("manageablePeople", newManageablePeople)
    }

    if (nonMatchingEmails.length) {
      setError("manageablePeople", {
        type: "custom",
        message: `${nonMatchingEmails.join(", ")} could not be added as the email ${pluralize(nonMatchingEmails.length, "address does", "addresses do")} not match any current people`,
      })
    } else {
      clearErrors("manageablePeople")
    }
  }

  return (
    <div className={styles.panel}>
      <div>
        <Controller
          control={control}
          name="permissions.manage_people"
          render={({ field: { onChange, value } }) => (
            <Select
              className={styles.input}
              onChange={(e) => onChange(e.value)}
              label={PeopleAccessLabel}
              value={PEOPLE_ACCESS_OPTIONS.find(
                (option) => option.value === value,
              )}
              options={PEOPLE_ACCESS_OPTIONS}
            />
          )}
        />
        <Support>
          {managePeoplePermission === "all"
            ? "Can Manage All People"
            : "Can Manage selected People"}
        </Support>
      </div>
      {managePeoplePermission === "restricted" && (
        <Controller
          control={control}
          name="manageablePeople"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div className={styles.section}>
              <Select
                id="managed-people"
                value={peopleOptions.filter((option) =>
                  value.includes(option.value),
                )}
                isMulti
                onChange={(e) => {
                  onChange(e.map((item) => item.value))
                  clearErrors("manageablePeople")
                }}
                label={ManagedPeopleLabel}
                options={peopleOptions}
                placeholder="Type Persons name..."
                onKeyDown={onKeyDown}
                onInputChange={setInputValue}
                inputValue={inputValue}
                menuIsOpen={open}
                onMenuOpen={() => setOpen(true)}
                onMenuClose={() => setOpen(false)}
              />
              {error && <Support intent="danger">{error.message}</Support>}
            </div>
          )}
        />
      )}
      <Callout>
        {
          "Allows the manager to edit people's details, time off, contract and allocate to all projects"
        }
      </Callout>
    </div>
  )
}

export default PeoplePanel
