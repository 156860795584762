/**
 * @generated SignedSource<<9ae5635c7e55b425d148bde57aaf5f58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HolidaysGroupList_viewer$data = {
  readonly holidays_groups: ReadonlyArray<{
    readonly country_code: string;
    readonly country_name: string | null | undefined;
    readonly holidays_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null | undefined;
    };
    readonly id: number;
    readonly name: string;
    readonly people_aggregate: {
      readonly aggregate: {
        readonly count: number;
      } | null | undefined;
    };
    readonly region_name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EditHolidaysGroupForm_holidays_group">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"EditHolidaysGroupForm_account">;
  readonly " $fragmentType": "HolidaysGroupList_viewer";
};
export type HolidaysGroupList_viewer$key = {
  readonly " $data"?: HolidaysGroupList_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HolidaysGroupList_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HolidaysGroupList_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order_by",
          "value": {
            "name": "asc"
          }
        }
      ],
      "concreteType": "holidays_groups",
      "kind": "LinkedField",
      "name": "holidays_groups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country_code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "region_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "distinct_on",
              "value": "name"
            }
          ],
          "concreteType": "holidays_aggregate",
          "kind": "LinkedField",
          "name": "holidays_aggregate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "holidays_aggregate_fields",
              "kind": "LinkedField",
              "name": "aggregate",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": "holidays_aggregate(distinct_on:\"name\")"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "distinct_on",
              "value": "id"
            },
            {
              "kind": "Literal",
              "name": "where",
              "value": {
                "active": {
                  "_eq": true
                }
              }
            }
          ],
          "concreteType": "people_aggregate",
          "kind": "LinkedField",
          "name": "people_aggregate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "people_aggregate_fields",
              "kind": "LinkedField",
              "name": "aggregate",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": "people_aggregate(distinct_on:\"id\",where:{\"active\":{\"_eq\":true}})"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditHolidaysGroupForm_holidays_group"
        }
      ],
      "storageKey": "holidays_groups(order_by:{\"name\":\"asc\"})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditHolidaysGroupForm_account"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};
})();

(node as any).hash = "c8182d2a2a39348f148c0a066acfc94c";

export default node;
