import { Tab } from "@blueprintjs/core"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./UserModal.module.css"

import { UserModalQuery } from "./__generated__/UserModalQuery.graphql"

import { ModalHeader } from "~/common/ModalForm"
import Tabs from "~/common/Tabs"

import PeoplePanel from "./Panels/PeoplePanel"
import PersonalDetailsPanel from "./Panels/PersonalDetailsPanel"
import ProjectsPanel from "./Panels/ProjectsPanel"
import UserTypePanel from "./Panels/UserTypePanel"
import UserModalFooter from "./UserModalFooter"
import { Mode, User, UserForm, schema } from "./userForm"

const QUERY = graphql`
  query UserModalQuery($userId: Int!, $skip: Boolean!) {
    user: users_by_pk(id: $userId) @skip(if: $skip) {
      id
      manageable_people {
        id
        person_id
      }
      manageable_projects {
        id
        project_id
      }
    }
  }
`

const fieldsToValidateByTab = {
  personalDetails: ["email"],
  userType: [],
  people: [],
  projects: [],
}

type InviteProps = {
  mode: Mode.INVITE
  email?: string
  onClose: () => void
}

type EditProps = {
  mode: Mode.EDIT
  user: User
  onClose: () => void
}

const UserModal = (props: InviteProps | EditProps) => {
  const { mode, onClose } = props

  const [selectedTabId, changeSelectedTab] = useState("personalDetails")

  // Query for manageable people & projects for selected user
  const data = useLazyLoadQuery<UserModalQuery>(QUERY, {
    userId: mode === Mode.EDIT ? props.user.id : null,
    skip: mode !== Mode.EDIT, // Only load data if editting a user
  })

  let defaultValues: Partial<UserForm> = {
    firstName: "",
    lastName: "",
    email: "",
    permissions: {
      type: "contributor",
      financial: "none",
      manage_projects: "none",
      manage_people: "none",
      add_all_people_to_projects: false,
      manage_account: false,
      view_planner: false,
    },
    manageablePeople: [],
    manageableProjects: [],
  }

  if (mode === Mode.EDIT && data) {
    const { user } = props

    // Setup the form values from the users details, permissions, and manageable people/projects
    defaultValues = {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      permissions: user.permissions,
      manageablePeople: data.user.manageable_people.map(
        ({ person_id }) => person_id,
      ),
      manageableProjects: data.user.manageable_projects.map(
        ({ project_id }) => project_id,
      ),
    }
  }

  if (mode === Mode.INVITE && props.email) {
    defaultValues.email = props.email
  }

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm<UserForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onTouched", // When to validate fields
  })

  // Watch for changes to the form data so that tabs can be re-rendered (i.e. hidden) as needed
  useWatch({
    control,
    name: ["permissions", "manageablePeople", "manageableProjects"],
  })

  const isManager = getValues("permissions.type") === "manager"

  const managePeoplePermission = getValues("permissions.manage_people")
  const manageProjectsPermission = getValues("permissions.manage_projects")

  const peopleTabTitle =
    managePeoplePermission === "all"
      ? "People (All)"
      : `People (${getValues("manageablePeople").length})`
  const projectsTabTitle =
    manageProjectsPermission === "all"
      ? "Projects (All)"
      : `Projects (${getValues("manageableProjects").length})`

  // Controlled tabs for INVITE mode
  const availableTabs = [
    "personalDetails",
    "userType",
    ...(isManager && managePeoplePermission !== "none" ? ["people"] : []),
    ...(isManager && manageProjectsPermission !== "none" ? ["projects"] : []),
  ]

  const isTabValid = () => trigger(fieldsToValidateByTab[selectedTabId])

  const navigateToNextTab = async () => {
    if (await isTabValid()) {
      changeSelectedTab(availableTabs[availableTabs.indexOf(selectedTabId) + 1])
    }
  }

  const navigateToPreviousTab = () => {
    changeSelectedTab(availableTabs[availableTabs.indexOf(selectedTabId) - 1])
  }

  return (
    <div className={styles.modal} data-test="user-modal">
      <ModalHeader
        className={styles.header}
        text={
          mode === Mode.INVITE
            ? "Add User"
            : `Edit ${props.user.first_name} ${props.user.last_name}`
        }
        onClose={onClose}
      />
      <Tabs
        id="permissionsTabs"
        className={styles.tabs}
        large
        selectedTabId={selectedTabId}
        onChange={async (value) => {
          if (await isTabValid()) {
            changeSelectedTab(value.toString())
          }
        }}
      >
        <Tab
          id="personalDetails"
          title="Personal Details"
          panel={
            <PersonalDetailsPanel
              control={control}
              mode={mode}
              setError={setError}
              user={mode === Mode.EDIT ? props.user : undefined}
            />
          }
        />
        <Tab
          id="userType"
          title="User Type"
          panel={<UserTypePanel control={control} getValues={getValues} />}
        />
        <Tab
          hidden={!isManager || managePeoplePermission === "none"}
          id="people"
          title={peopleTabTitle}
          panel={
            <PeoplePanel
              control={control}
              getValues={getValues}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
            />
          }
        />
        <Tab
          hidden={!isManager || manageProjectsPermission === "none"}
          id="projects"
          title={projectsTabTitle}
          panel={<ProjectsPanel control={control} getValues={getValues} />}
        />
      </Tabs>

      <UserModalFooter
        mode={mode}
        user={mode === Mode.EDIT && props.user}
        onClose={onClose}
        handleSubmit={handleSubmit}
        selectedTabId={selectedTabId}
        availableTabs={availableTabs}
        navigateToPreviousTab={navigateToPreviousTab}
        navigateToNextTab={navigateToNextTab}
      />
    </div>
  )
}

export default UserModal
