/**
 * @generated SignedSource<<6c2f433e0674e3271a3a0a24f4f39f58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EntitlementsStore_refetchQuery$variables = Record<PropertyKey, never>;
export type EntitlementsStore_refetchQuery$data = {
  readonly billing: {
    readonly entitlements: ReadonlyArray<{
      readonly featureId: string;
      readonly featureType: string;
      readonly value: string;
    }> | null | undefined;
    readonly subscription: {
      readonly id: string;
      readonly isInTrial: boolean;
      readonly plan: {
        readonly itemPriceId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EntitlementsStore_refetchQuery = {
  response: EntitlementsStore_refetchQuery$data;
  variables: EntitlementsStore_refetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "syncSubscription",
    "value": false
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInTrial",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemPriceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingSubscriptionEntitlement",
  "kind": "LinkedField",
  "name": "entitlements",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featureType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EntitlementsStore_refetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": "billing(syncSubscription:false)"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EntitlementsStore_refetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": "billing(syncSubscription:false)"
      }
    ]
  },
  "params": {
    "cacheID": "433a5ba8bf551227afba14dc48ed2145",
    "id": null,
    "metadata": {},
    "name": "EntitlementsStore_refetchQuery",
    "operationKind": "query",
    "text": "query EntitlementsStore_refetchQuery {\n  billing(syncSubscription: false) {\n    subscription {\n      id\n      isInTrial\n      plan {\n        itemPriceId\n        id\n      }\n    }\n    entitlements {\n      featureId\n      featureType\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "683763d6cdbd15a5bdf02f1feedce323";

export default node;
