import React, { useEffect } from "react"
import { graphql, useFragment } from "react-relay"

import { EntitlementsProvider_billing$key } from "./__generated__/EntitlementsProvider_billing.graphql"

import { SUBSCRIPTIONS } from "~/GLOBALVARS"

import EntitlementsContext, {
  EntitlementFeatureType,
} from "./EntitlementsContext"
import { getEntitlementStore } from "./EntitlementsStore"

type Props = {
  billing: EntitlementsProvider_billing$key
  children: React.ReactNode
}

const FRAGMENT = graphql`
  fragment EntitlementsProvider_billing on Billing {
    subscription {
      id
      isInTrial
      plan {
        itemPriceId
      }
    }
    entitlements {
      featureId
      featureType
      value
    }
  }
`

const EntitlementsProvider = (props: Props) => {
  const { children, billing } = props
  const fragmentData = useFragment(FRAGMENT, billing)

  // Use the billing store to make sure we periodically refetch the subscription details
  // if it doesn't exist yet. This can happen when an account first signs up. An account
  // is created first before a background task creates the subscription. In some cases
  // the app can load before the subscription creation job has run.
  const entitlementStore = getEntitlementStore({ billing: fragmentData })
  const entitlementData = entitlementStore.useStore()

  // Periodically go and refetch the subscription details if it's not complete.
  useEffect(() => {
    if (entitlementData?.entitlements?.length) {
      return
    }

    entitlementStore.fetchData()
  }, [entitlementData?.entitlements, entitlementStore])

  return (
    <EntitlementsContext.Provider
      value={{
        entitlements: entitlementData?.entitlements?.length
          ? entitlementData.entitlements.map((e) => ({
              ...e,
              featureType: e.featureType as EntitlementFeatureType,
            }))
          : null,
        isInTrial: entitlementData?.subscription?.isInTrial ?? false,
        plan:
          entitlementData?.subscription?.plan?.itemPriceId ??
          SUBSCRIPTIONS.pro_plan_id,
      }}
    >
      {children}
    </EntitlementsContext.Provider>
  )
}

export default EntitlementsProvider
