/**
 * @generated SignedSource<<94c2250056eb926bd58001d0dc211306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HolidaysTableQuery$variables = {
  countryCode: string;
  year: number;
};
export type HolidaysTableQuery$data = {
  readonly action_holidays_get: {
    readonly holidays: ReadonlyArray<any> | null | undefined;
  } | null | undefined;
};
export type HolidaysTableQuery = {
  response: HolidaysTableQuery$data;
  variables: HolidaysTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "year"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "country_code",
        "variableName": "countryCode"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "concreteType": "HolidaysGetOutput",
    "kind": "LinkedField",
    "name": "action_holidays_get",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "holidays",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HolidaysTableQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HolidaysTableQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7163f464b2a112afd172af423a24c869",
    "id": null,
    "metadata": {},
    "name": "HolidaysTableQuery",
    "operationKind": "query",
    "text": "query HolidaysTableQuery(\n  $countryCode: String!\n  $year: Int!\n) {\n  action_holidays_get(country_code: $countryCode, year: $year) {\n    holidays\n  }\n}\n"
  }
};
})();

(node as any).hash = "84c95d19a517c4b1b8511ab6bf394d59";

export default node;
